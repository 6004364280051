import React, { Fragment, useState } from "react";
import { useSharedData } from "../SharedDataContext";
import { useUser } from "../SharedDataContext";
import BenutzerInfoItem from "./BenutzerInfoItem";

const BenutzerList = (props) => {
	const { users, departments } = useSharedData();
	const { loggedInUser } = useUser();
	const firmUsers = users.filter(
		(user) => user.fkCompanyID === loggedInUser.fkCompanyID
	);
	const [show, setShow] = useState(false);
	const [user, setUser] = useState([{ pkUserID: 0 }]);

	const [changeField, setchangeField] = useState(false);
	const showField = () => {
		if (changeField) {
			setchangeField(false);
		} else {
			setchangeField(true);
		}
	};

	const useritems = user.map((item, index) => (
		<Fragment key={`userDetails_${index}`}>
			<BenutzerInfoItem
				label="Name"
				strEditType="text"
				strValueID="strSurName"
				value={item.strSurName}
				id={item.pkUserID}
			/>
			<BenutzerInfoItem
				label="Vorname"
				strEditType="text"
				strValueID="strFirstName"
				value={item.strFirstName}
				id={item.pkUserID}
			/>
		</Fragment>
	));

	const clickButtonHandler = (id) => {
		setShow(false);
		const user = users.filter((u) => u.pkUserID === Number(id));
		setUser(user);
		setShow(true);
		showField();
	};

	const closeDetails = () => {
		setShow(false);
		showField();
	};

	return (
		<div>
			{!changeField && (
				<>
					<h2>Benutzerlist</h2>
					<table className="BenutzerList">
						<thead>
							<tr>
								<th>ID</th>
								<th>Name</th>
								<th>Vorname</th>
								<th>Email</th>
								<th>Bereich</th>
								<th>Erstellungsdatum</th>
								<th>Aktion</th>
							</tr>
						</thead>

						<tbody>
							{firmUsers.map((user) => (
								<tr key={user.pkUserID}>
									<td>{user.pkUserID}</td>
									<td>{user.strSurName}</td>
									<td>{user.strFirstName}</td>
									<td>{user.strEmail}</td>
									<td>
										{
											departments.find(
												(bereich) =>
													bereich.pkDepartmentID ===
													user.intMainDepartment
											).strDepartment
										}
									</td>
									<td>{user.datCreate}</td>
									<td style={{ width: "200px" }}>
										<button
											type="button"
											onClick={() =>
												clickButtonHandler(
													user.pkUserID
												)
											}
											style={{
												margin: "0px",
												width: "170px",
											}}
										>
											Details
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			)}
			{show && changeField && (
				<div>
					<h2>Benutzer Details</h2>
					<table className="BenutzerDetailHeaderTable">
						<thead>
							<tr
								className="BenutzerDetailHeader"
								onClick={closeDetails}
							>
								<td>
									<p>
										<span>X</span> Schließen
									</p>
								</td>
							</tr>
						</thead>
					</table>
					<table className="loggedInUser">
						<tbody>{useritems}</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default BenutzerList;
