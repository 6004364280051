import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useSharedData } from "../SharedDataContext";
import Chart from "chart.js/auto";
//Chart.register();

const DashboardDivision = (props) => {
	const {
		filteredPrompts,
		users,
		departments,
		markiertePrompts,
		loggedInUser,
	} = useSharedData();
	const newprompts = [];
	const authorCounts = {};
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: "Number of Articles per Author",
				data: [],
				backgroundColor: "rgba(75, 192, 192, 0.6)",
			},
		],
	});
	//{ label, checked, onChange }

	const getDivisionName = (divisionID) => {
		const division = departments.find(
			(div) => div.pkDepartmentID === Number(divisionID)
		);
		return division ? division.strDepartment : "Unbekannt";
	};

	const getDivisionID = (userID) => {
		const division = users.find((div) => div.pkUserID === Number(userID));
		return division ? division.intMainDepartment : "Unbekannt";
	};

	useEffect(() => {
		// Function to get division name by ID
		if (newprompts.length === 0) {
			for (const elm of filteredPrompts) {
				let newItem = {};
				newItem["pkPromptID"] = elm.pkPromptID;
				let userid = elm.fkUserID;
				let divid = getDivisionID(userid);
				newItem["intMainDepartment"] = divid;
				newItem["BereichName"] = getDivisionName(divid);
				newprompts.push(newItem);
			}

			newprompts.forEach((article) => {
				if (authorCounts[article.BereichName]) {
					authorCounts[article.BereichName]++;
				} else {
					authorCounts[article.BereichName] = 1;
				}
			});

			const labels = Object.keys(authorCounts);
			const dataCounts = Object.values(authorCounts);

			setChartData({
				labels,
				datasets: [
					{
						label: "Prompts Anzahl pro Bereich",
						data: dataCounts,
						backgroundColor: "rgba(75, 192, 192, 0.6)",
					},
				],
			});
		}
	}, []);

	return (
		<div className="chart1">
			<h1>Dashboard Bereiche</h1>
			<Bar data={chartData} options={{ maintainAspectRatio: false }} />
		</div>
	);
};

export default DashboardDivision;
