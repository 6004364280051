import React from 'react'

const ChatDropdown = ({ selectedOption, handleOptionChange }) => {
  return (
    <div className='PromptsArt'>
      <label>Promptsart:</label>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value='option1'>Normal</option>
        <option value='option2'>Unstrukturierte</option>
        <option value='option3'>Strukturiert</option>
        <option value='option4'>Assistent</option> 
      </select>
    </div>
  )
}

export default ChatDropdown
