import React, { useState } from "react";

const KategorieForm = (props) => {
	const [neueKategorie, setNeueKategorie] = useState("");

	const handleInputChange = (event) => {
		setNeueKategorie(event.target.value);
	};

	const submitHandler = (event) => {
		event.preventDefault(); // Prevent the default form submission behavior
		// Access the 'neueKategorie' value from the state variable and use it as needed
		const kategorieExists = props.kategorieList.some(
			(item) =>
				item.strPromptCategoryName.toLowerCase() ===
				neueKategorie.toLowerCase()
		);
		if (kategorieExists) {
			window.alert(`Die Kategorie '${neueKategorie}' existiert schon!`);
		} else {
			props.onSaveData(neueKategorie);
		}
		// You can save it in another variable or perform any other actions here
	};

	return (
		<form className="KategorieForm">
			<div className="inputFeld">
				<label>Kategorie Name </label>
				<input type="text" onChange={handleInputChange} />
			</div>
			<div className="buttonsFeld">
				<button
					className="abbrechenBtn"
					type="button"
					onClick={props.onCancel}
				>
					Abbrechen
				</button>
				<button
					className="submitBtn"
					type="submit"
					onClick={submitHandler}
				>
					hinzufügen
				</button>
			</div>
		</form>
	);
};

export default KategorieForm;
