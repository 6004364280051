import React from "react";
import swal from "sweetalert";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { callBackend, useSharedData, useUser } from "../SharedDataContext";

import ChatArtDropdown from "../component/ChatArtDropdown";
import ChatComponent from "../component/ChatComponent";
import ChatDropdown from "../component/ChatDropdown";
import Header from "../component/Header";
import KategorienComponent from "../component/Kategorien_Unterkategorien_Eigenschaften/KategorienComponent";
import TagsComponent from "../component/Tags/TagsComponent";
import SeitenListe from "../component/SeitenListe";

const Erfassung = () => {
	const navigate = useNavigate();

	const [bemerkungWert, setBemerkungWert] = useState("");
	const [ergebnisWert, setErgebnisWert] = useState("");
	const [eigenschaftenWert, setEigenschaftenWert] = useState([]);
	const [chatType, setChatType] = useState("");
	const [tagsList, setTagsList] = useState([]);

	const { tags, setTags, promptTags, setPromptTags } = useSharedData();
	const { prompts, setPrompts } = useSharedData();
	const { promptData, setPromptData } = useSharedData();
	const { setPromptCategories } = useSharedData();

	const { loggedInUser } = useUser();

	useEffect(() => {
		if (!loggedInUser) {
			navigate("/");
		}
	}, []);

	const pageName = {
		name: "Erfassung",
		link1: "/",
		link2: "/",
		hide1: "yes",
		hide2: "no",
		userId: 0,
	};

	const openNotizDialog = () => {
		swal("Möchten Sie eine Notiz hinzugügen?", {
			buttons: {
				cancel: "Abbrechen",
				confirm: true,
			},
			content: "input",
		}).then((notizValue) => {
			if (notizValue != undefined) {
				addTitelDialog(notizValue);
			}
		});
	};
	const addTitelDialog = (notizValue) => {
		swal("Geben Sie bitte einen Titel ein!", {
			buttons: {
				cancel: "Abbrechen",
				confirm: true,
			},
			content: "input",
		}).then((titelValue) => {
			if (titelValue != undefined) {
				if (titelValue.trim() === "") {
					noTitle(notizValue);
				} else {
					swal(
						`Das Prompt wurde mit Titel ${titelValue} hinzugefügt gespeichert.`
					).then(() => {
						addPrompt(notizValue, titelValue);
					});
				}
			}
		});
	};
	const noTitle = (notizValue) => {
		swal("Der Title kann nicht leer sein", {
			button: false,
			timer: 1000,
		}).then(() => addTitelDialog(notizValue));
	};

	let promptID;

	const addPrompt = (notizValue, titelValue) => {
		let input = document.getElementById("inputText").value;
		let output = document.getElementById("outputText").textContent;
		callBackend({
			action: "save_prompt",
			payload: {
				fkUserID: loggedInUser.pkUserID,
				fkDataPromptRoleID: promptData.fkDataPromptRoleID || 0,
				fkDataPromptProvideID: promptData.fkDataPromptProvideID || 0,
				fkDataPromptOutputID: promptData.fkDataPromptOutputID || 0,
				fkDataPromptPlatformID: promptData.fkDataPromptPlatformID || 0,
				fkDataLanguageID: promptData.fkDataLanguageID || 0,
				strPromptName: titelValue,
				intPromptKind: promptData.intPromptKind || 0,
				txtSubject: promptData.txtSubject || null,
				txtImput: input,
				txtOutput: output,
				txtComment: notizValue,
				intMinLength: promptData.intMinLength || 0,
				intMaxLength: promptData.intMaxLength || 0,
				txtAudience: promptData.txtAudience || null,
				txtCallToAction: promptData.txtCallToAction || null,
				txtForm: promptData.txtForm || null,
				intEmojis: promptData.intEmojis || 0,
			},
		}).then((rst) => {
			setPrompts([
				...prompts,
				{
					pkPromptID: rst.pkPromptID,
					fkUserID: loggedInUser.pkUserID,
					fkDataPromptRoleID: promptData.fkDataPromptRoleID || 0,
					fkDataPromptProvideID:
						promptData.fkDataPromptProvideID || 0,
					fkDataPromptOutputID: promptData.fkDataPromptOutputID || 0,
					fkDataPromptPlatformID:
						promptData.fkDataPromptPlatformID || 0,
					fkDataLanguageID: promptData.fkDataLanguageID || 0,
					strPromptName: titelValue,
					intPromptKind: promptData.intPromptKind || 0,
					txtSubject: promptData.txtSubject || null,
					txtImput: input,
					txtOutput: output,
					txtComment: notizValue,
					intMinLength: promptData.intMinLength || 0,
					intMaxLength: promptData.intMaxLength || 0,
					txtAudience: promptData.txtAudience || null,
					txtCallToAction: promptData.txtCallToAction || null,
					txtForm: promptData.txtForm || null,
					intEmojis: promptData.intEmojis || 0,
					datCreate: rst.datCreate,
					datTsControl: rst.datTsControl
				},
			]);
			let date = new Date().toISOString().slice(0, 10);

			eigenschaftenWert.map((obj) => {
				callBackend({
					action: "save_prompt_category",
					payload: {
						fkUserID: loggedInUser.pkUserID,
						fkPromptID: rst.pkPromptID,
						fkDataPromptCategoryID: obj.fkDataPromptCategoryID,
						fkDataPromptSubCategoryID:
							obj.fkDataPromptSubCategoryID,
					},
				}).then((rst2) => {
					setPromptCategories(oldCategories=>[
						...oldCategories,
						{
							pkPromptCategoryID: rst2.pkPromptCategoryID || 0,
							fkUserID: loggedInUser.pkUserID,
							fkPromptID: rst.pkPromptID,
							fkDataPromptCategoryID:
								obj.fkDataPromptCategoryID || 0,
							fkDataPromptSubCategoryID:
								obj.fkDataPromptSubCategoryID || 0,
							// intStatus: 1, // ToDo: vom Server zurückgeben?
							// datTsControl: rst.datTsControl
						},
					]);
				});
			});

			tagsList.forEach((obj) => {
				callBackend({
					action: "save_prompt_tag",
					payload: {
						fkPromptID: rst.pkPromptID,
						fkDataPromptTagID: obj.pkDataPromptTagID,
					},
				});
			});
			const newPromptTags = tagsList.map((obj) => ({
				fkPromptID: rst.pkPromptID,
				fkDataPromptTagID: obj.pkDataPromptTagID,
				intStatus: 1,
				datTsControl: date,
			}));
			setPromptTags([...promptTags, ...newPromptTags]);
		});

		setErgebnisWert("");
		setBemerkungWert("");
		setEigenschaftenWert([]);
		setTagsList([]);
		setPromptData({ action: "reset_prompt" });
	};

	const clickSaveButtonHandler = (event) => {
		event.preventDefault();
		openNotizDialog();
	};

	const [selectedChatType, setSelectedChatType] = useState("option1");
	const [selectedArt, setSelectedArt] = useState("art1");
	const [disableArt, setDisableArt] = useState(true);
	const [showBlock1, setShowBlock1] = useState(true);
	const [showBlock2, setShowBlock2] = useState(false);
	const [showBlock3, setShowBlock3] = useState(false);
	const [showBlock4, setShowBlock4] = useState(false);
	

	const handleOptionChange = (event) => {
		const selectedOption = event.target.value;
		setSelectedChatType(selectedOption);
		setChatType(selectedOption);
		setShowBlock1(selectedOption === "option1");
		setShowBlock2(selectedOption === "option2");
		setShowBlock3(selectedOption === "option3");
		setShowBlock4(selectedOption === "option4");
		
		setPromptData({action:"reset_prompt"})
		//setInputText('')
		//setOutputText('')
	};

	const handleArtChange = (event) => {
		const selectedArtOption = event.target.value;
		setSelectedArt(selectedArtOption);
	};

	useEffect(() => {
		setChatType(selectedChatType);
		setSelectedArt("art1");
		if (selectedChatType === "option4") {
			setDisableArt(false);
		} else {
			setDisableArt(true);
		}
	}, []);

	useEffect(() => {
		if (selectedChatType === "option4") {
			setDisableArt(false);
		} else {
			setDisableArt(true);
		}
	}, [selectedChatType]);

	// useEffect(() => {
	// 	const cleanup = () => {
	// 		setPromptCategories(
	// 			promptCategories.filter((elm) => elm.fkPromptID !== undefined)
	// 		);
	// 	};
	// 	return cleanup;
	// }, []);

	useEffect(() => {
		setPromptData({ action: "reset_prompt" });
	}, []);
	return (
		<div>
			<Header items={pageName} />
			<SeitenListe />
			<section className="Erfassungsseite">
				<div className="wrapper">
					<div className="SeiteForm">
						<div className="OptionForm">
							<ChatDropdown
								selectedOption={selectedChatType}
								handleOptionChange={handleOptionChange}
							/>
							<ChatArtDropdown
								selectedArtOption={selectedArt}
								handleArtChange={handleArtChange}
								disabled={disableArt}
							/>
						</div>
						<KategorienComponent
							promptID={promptID}
							eigenschaftenWert={eigenschaftenWert}
							setEigenschaftenWert={setEigenschaftenWert}
						/>
						<TagsComponent
							tags={tags}
							setTags={setTags}
							tagsList={tagsList}
							setTagsList={setTagsList}
						/>
					</div>
					<div className="HauptForm">
						<ChatComponent
							bemerkungWert={bemerkungWert}
							ergebnisWert={ergebnisWert}
							chatType={chatType}
							setBemerkungWert={setBemerkungWert}
							setErgebnisWert={setErgebnisWert}
							showBlock1={showBlock1}
							showBlock2={showBlock2}
							showBlock3={showBlock3}
							showBlock4={showBlock4}
						/>
						<div className="speicherButton">
							<button
							disabled={!ergebnisWert.trim()}
								className="btn-right"
								onClick={clickSaveButtonHandler}
							>
								<p>Speichern</p>
							</button>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Erfassung;
