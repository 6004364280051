import React, { useEffect, useState } from 'react';
//import axios from 'axios';

const ChatAssistant = ({onAssistantValuesChange, chatType}) => {
    const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

const questions = [
    "Was ist das Hauptziel oder der Zweck des generierten Inhalts?",
    "Welches Thema oder welchen Schwerpunkt möchten Sie für den generierten Inhalt setzen?",
    "Gibt es spezifische Details, die im generierten Inhalt enthalten sein müssen?",
    "Welche Emotionen oder Stimmungen soll der generierte Inhalt vermitteln?",
    "Gibt es eine bevorzugte Tonalität oder Stil, den der generierte Inhalt haben sollte?",
    "Haben Sie spezielle Anforderungen an die Länge oder Formatierung des generierten Inhalts?",
    "Gibt es Beispiele oder Referenzen, die den gewünschten Inhalt näher beschreiben?",
    "Gibt es bestimmte Schlüsselwörter oder Phrasen, die im generierten Inhalt besonders hervorgehoben werden sollen?"
  ];

  useEffect(()=>{
    const newMessages = [...messages, { text: questions[0], isUser: false }];
    setMessages(newMessages);
   
  }, [])

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleSendMessage = async () => {
    if (!input) return;

    setCurrentQuestion(currentQuestion+1)

    const newMessages = [...messages, { text: input, isUser: true }];
    setMessages(newMessages);
    setInput('');
    setLoading(true);

    // Simulated response (replace this with your API request)
    let simulatedResponse = '';
    
    if (currentQuestion+1 < questions.length) {
        simulatedResponse=questions[currentQuestion+1];
      } else {
        simulatedResponse= "Vielen Dank für Ihre Eingaben. Der generierte Inhalt wird erstellt.";
        onAssistantValuesChange('test')
      }
      // "This is a simulated response from the assistant.";
    const updatedMessages = [...newMessages, { text: simulatedResponse, isUser: false }];

    // Simulate loading for a brief moment
    setTimeout(() => {
      setMessages(updatedMessages);
      setLoading(false);
    }, 1000); // Simulated delay

    // Replace the simulated response above with actual API requests
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.isUser ? 'user' : 'assistant'}`}>
            {message.text}
          </div>
        ))}
      </div>

      <div className="chat-input">
      {currentQuestion< questions.length ?  ( 
        <div>
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Ihre Antwort..."
        />

        <button onClick={handleSendMessage} /* The `disabled` attribute in the `<button>` element is
        used to disable the button. When the `disabled`
        attribute is present, the button is not clickable and
        the user cannot interact with it. In the code provided,
        the `disabled` attribute is used to disable the send
        button while the chat assistant is loading a response.
        This prevents the user from sending multiple messages
        while waiting for a response. */
        disabled={loading}>
          Send
        </button>
        </div>
        ): null}
      </div>

    </div>
  );
};


export default ChatAssistant;