import React from "react";
import { useState } from "react";
import { TiDelete } from "react-icons/ti";
import TagsList from "./TagsList";
import { useSharedData } from "../../SharedDataContext";

const TagsComponent = ({ tagsList, setTagsList }) => {
	const { tags, setTags } = useSharedData();
	const [tag, setTag] = useState("");

	const { loggedInUser, callBackend } = useSharedData();

	const handleAddTag = () => {
		if (tag.trim() !== "") {
			const tagAssigned = tagsList.some(
				(item) =>
					item.strDataPromptTag.toLowerCase() === tag.toLowerCase()
			);
			const tagExists = tags.find(
				//ToDo: Tag auch auf User überprüfen?
				(item) =>
					item.strDataPromptTag.toLowerCase() === tag.toLowerCase()
			);
			if (tagAssigned) {
				window.alert(`Das Tag '${tag}' existiert schon!`);
			} else if (tagExists) {
				setTagsList([...tagsList, tagExists]);
			} else {
				callBackend({
					action: "save_tag",
					payload: {
						strDataPromptTag: tag,
						fkUserID: loggedInUser.pkUserID,
					},
				})
					.then((rst) => {
						const datNow = new Date();
						const newTag = {
							pkDataPromptTagID: rst.pkDataPromptTagID,
							strDataPromptTag: tag,
							fkUserID: loggedInUser.pkUserID,
							Datum: `${datNow.getFullYear()}-${
								datNow.getMonth() + 1
							}-${datNow.getDate()}`,
						};

						setTags([...tags, newTag]);
						setTagsList([...tagsList, newTag]);
					})
					.catch((err) => console.log(err));

				setTag("");
			}
		}
		// Clear the input field after adding the tag
		setTag("");
	};

	return (
		<div className="tags">
			<div>
				<h3>Tags:</h3>
				<input
					type="text"
					list="tags"
					value={tag}
					onChange={(e) => setTag(e.target.value)}
				/>
			</div>
			<datalist id="tags">
				{tags.map((elm) => (
					<option
						value={elm.strDataPromptTag}
						key={`tag_option_${elm.pkDataPromptTagID}`}
					/>
				))}
			</datalist>
			<div className="buttons" onClick={handleAddTag}>
				<button>
					<p>Tag hinzufügen </p>
				</button>
			</div>

			<div className="Tags-container">
				{tagsList.length > 0 && (
					<TagsList
						tags={tags}
						setTags={setTags}
						tagsList={tagsList}
						setTagsList={setTagsList}
					/>
				)}
			</div>
		</div>
	);
};
export default TagsComponent;
