import { useState } from "react";
import { useSharedData } from "../SharedDataContext";
import PasswordChange from "./PasswordChange";
import BenutzerInfoItem from "./BenutzerInfoItem";

const BenutzerInfo = () => {
	const { loggedInUser, userZusBereichList, departments } = useSharedData();

	const [newPassword, setNewPassword] = useState(false);

	const handleChangePasswordClick = () => {
		setNewPassword(true);
	};

	const handleUpdatePassword = () => {
		setNewPassword(false);
	};
	const closePasswordChange = () => {
		setNewPassword(false);
	};

	return (
		<div>
			<h2>Personalinformationen</h2>

			<table className="loggedInUser">
				<tbody>
					<BenutzerInfoItem
						label="Name"
						strEditType="text"
						strValueID="strSurName"
						value={loggedInUser.strSurName}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label="Vorname"
						strEditType="text"
						strValueID="strFirstName"
						value={loggedInUser.strFirstName}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label="Email"
						strEditType="text"
						strValueID="strEmail"
						value={loggedInUser.strEmail}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label="Login"
						strEditType="text"
						strValueID="strUserName"
						value={loggedInUser.strUserName}
						userID={loggedInUser.pkUserID}
					/>
					<tr>
						<td className="td1">Passwort</td>
						<td>
							<div className="passwordDiv">
								{!newPassword && (
									<>
										<span></span>
										<button
											className=""
											onClick={handleChangePasswordClick}
										>
											Ändern
										</button>
									</>
								)}
							</div>
							<div>
								{newPassword && (
									<PasswordChange
										setNewPassword={handleUpdatePassword}
										onClose={closePasswordChange}
									/>
								)}
							</div>
						</td>
					</tr>
					<BenutzerInfoItem
						label="Erstellungsdatum"
						strEditType="disabled"
						value={loggedInUser.datCreate}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label="Bereiche"
						strEditType="multiselect"
						arrOptions={departments
							.filter(
								(department) =>
									department.fkCompanyID ===
									loggedInUser.fkCompanyID
							)
							.map((department) => ({
								value: department.pkDepartmentID,
								name: department.strDepartment,
							}))}
						value={userZusBereichList.map(
							(item) => item.pkDepartmentID
						)}
						userID={loggedInUser.pkUserID}
					/>

					{loggedInUser && (
						<BenutzerInfoItem
							label="Hauptbereich"
							strEditType="select"
							strValueID="intMainDepartment"
							arrOptions={userZusBereichList
								.filter(
									(department) =>
										department.fkCompanyID ===
										loggedInUser.fkCompanyID
								)
								.map((department) => ({
									value: department.pkDepartmentID,
									name: department.strDepartment,
								}))}
							value={loggedInUser.intMainDepartment}
							userID={loggedInUser.pkUserID}
						/>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default BenutzerInfo;
