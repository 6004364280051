import React, { useState } from "react";
import { callBackend, useSharedData } from "../SharedDataContext";

const BereichItem = (props) => {
	const { departments, setDepartments, loggedInUser } = useSharedData();
	const value = props.name;
	const [isEditing, setIsEditing] = useState(false);
	const [editedValue, setEditedValue] = useState(value);
	const handleEditClick = () => {
		setIsEditing(!isEditing);
	};
	const handleSaveEditClick = () => {
		callBackend({
			action: "update_department",
			payload: {
				pkDepartmentID: props.id,
				strDepartment: editedValue,
			},
		})
			.then((rst) => {
				setDepartments(
					departments.map((obj) =>
						obj.pkDepartmentID === props.id
							? { ...obj, strDepartment: editedValue }
							: obj
					)
				);
				setIsEditing(false);
			})
			.catch((err) => console.log(err));
	};
	const handleInputChange = (e) => {
		setEditedValue(e.target.value);
	};
	return (
		<tr>
			<td>{props.index}</td>
			<td>
				{isEditing ? (
					<div>
						<input
							type="text"
							value={editedValue}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<div>
						<span>{editedValue || value}</span>
					</div>
				)}
			</td>
			<td>{props.date}</td>
			{loggedInUser.fkDataRoleID === 0 && (
				<td style={{width:"430px", textAlign:"center"}}>
					{isEditing ? (
						<button type="button" onClick={handleSaveEditClick}>
							Speichern
						</button>
					) : (
						<button type="button" onClick={handleEditClick}>
							Ändern
						</button>
					)}
					<button type="button" onClick={props.onDelete}>
						Löschen
					</button>
				</td>
			)}
		</tr>
	);
};

export default BereichItem;
