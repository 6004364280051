import { useState, useEffect } from "react";
import TagItem from "./TagItem";

const TagsList = ({ tags, setTags, tagsList, setTagsList, isChangeable }) => {
	return (
		<div>
			{tagsList.map((item) => (
				<TagItem
					key={item.pkDataPromptTagID}
					id={item.pkDataPromptTagID}
					name={item.strDataPromptTag}
					tags={tags}
					setTags={setTags}
					tagsList={tagsList}
					setTagsList={setTagsList}
					isChangeable={true}
				/>
			))}
		</div>
	);
};

export default TagsList;
