// SharedDataContext.jser
import React, {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from "react";
import axios from "axios";

const SharedDataContext = createContext();

const objConfig = {
	authToken: "",
	strUrl: "https://prompt.firmenpunkt-test.de/prompt.backend.php",
};

export const callBackend = async (objPayload) => {
	if (typeof objPayload.action != "string") {
		return { serverAction: "malformed_payload" };
	}
	let objResponse = {};
	const res = await axios
		.post(objConfig.strUrl, objPayload, {
			headers: { "content-type": "application/json" },
		})
		.catch((err) => {
			console.log(err);
			return {
				serverAction: "connection_error",
				status: err.response?.status,
				statusText: err.response?.statusText,
			};
		});

	objResponse = res.data;

	return objResponse;
};

export const useUser = () => {
	return useContext(SharedDataContext);
};

export const formatValues = (arrEntries) => {
	return arrEntries.map((obj) => {
		let objFormatted = {};
		Object.keys(obj).forEach((key) => {
			if (/^(pk|fk|int)/.test(key)) {
				Object.defineProperty(objFormatted, key, {
					value: Number(obj[key]),
					enumerable: true,
				});
			} else {
				Object.defineProperty(objFormatted, key, {
					value: obj[key],
					enumerable: true,
				});
			}
		});
		return objFormatted;
	});
};

export function SharedDataProvider({ children }) {
	const [kategorieList, setKategorieList] = useState([]);
	const [unterkategorieList, setUnterkategorieList] = useState([]);
	const [userZusBereichList, setUserZusBereichList] = useState([]);
	const [firmusers, setFirmUsers] = useState([]);
	const [markiertePrompts, setMarkiertePrompts] = useState([]);
	const [loggedInUser, setLoggedInUser] = useState(null);
	const [error, setError] = useState(null);
	const [fkCompanyID, setFkCompanyID] = useState(null);

	const [promptCategories, setPromptCategories] = useState([]);
	const [promptTags, setPromptTags] = useState([]);
	const [filteredPrompts, setFilteredPrompts] = useState([]);

	const [departments, setDepartments] = useState([]);
	const [firms, setFirms] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [outputs, setOutputs] = useState([]);
	const [platforms, setPlatforms] = useState([]);
	const [prompts, setPrompts] = useState([]);
	const [roles, setRoles] = useState([]);
	const [styles, setStyles] = useState([]);
	const [tags, setTags] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [users, setUsers] = useState([]);
	const [userDepartments, setUserDepartments] = useState([]);
	const [zipCodes, setZipCodes] = useState([]);

	const objPromptDataFormat = {
		pkPromptID: 0,
		fkUserID: 0,
		fkDataPromptRoleID: 0,
		fkDataPromptProvideID: 0,
		fkDataPromptOutputID: 0,
		fkDataPromptPlatformID: 0,
		fkDataLanguageID: 0,
		strPromptName: null,
		intPromptKind: 0,
		txtSubject: null,
		txtImput: null,
		txtOutput: null,
		txtComment: null,
		intMinLength: 0,
		intMaxLength: 0,
		txtAudience: null,
		txtCallToAction: null,
		txtForm: null,
		intEmojis: 0,
		datCreate: null,
		intFlag: 0,
		intStatus: 0,
		datTsControl: null,
	};

	const fnHandlePromptData = (objState, { strID, value, action }) => {
		if (action === "reset_prompt") {
			return objPromptDataFormat;
		}
		let objChanges = {};
		Object.defineProperty(objChanges, strID, {
			value: value,
			enumerable: true,
		});
		return { ...objState, ...formatValues([objChanges])[0] };
	};

	const [promptData, setPromptData] = useReducer(
		fnHandlePromptData,
		objPromptDataFormat
	);

	const handleLogin = (user) => {
		setLoggedInUser(user);
		setError(null);

		const newuserList = users.filter(
			(u) => u.fkCompanyID === user.fkCompanyID
		);
		const zusbereiche = userDepartments.filter(
			(u) => u.fkUserID === user.pkUserID
		);
		const allebereiche = [];

		if (zusbereiche && zusbereiche.length > 0) {
			for (const key in zusbereiche) {
				allebereiche.push(zusbereiche[key].fkDepartmentID);
			}
		}
		//setUserZusBereichList

		if (allebereiche.length > 0) {
			let filteredBereiche = departments.filter((item) =>
				allebereiche.includes(item.pkDepartmentID)
			);
			setUserZusBereichList(filteredBereiche);
		}

		allebereiche.push(user.intMainDepartment);

		let filteredUserIDs = newuserList.map((item) => item.pkUserID);
		setFkCompanyID(user.fkCompanyID);
		setFirmUsers(newuserList);

		if (user.fkDataRoleID === 0) {
			setFilteredPrompts(
				prompts.filter((item) =>
					filteredUserIDs.includes(item.fkUserID)
				)
			);
		} else {
			//Änderung !

			let filteredBereicheUsers = users.filter((item) =>
				allebereiche.includes(item.intMainDepartment)
			);
			let filteredBereicheUsersIDs = filteredBereicheUsers.map(
				(item) => item.pkUserID
			);

			setFilteredPrompts(
				prompts.filter((item) =>
					filteredBereicheUsersIDs.includes(item.fkUserID)
				)
			);
		}
	};

	const handleLogout = () => {
		setLoggedInUser(null);
	};

	useEffect(() => {
		if (loggedInUser) {
			const allebereiche = userDepartments
				.filter((u) => u.fkUserID === loggedInUser.pkUserID)
				.map((obj) => obj.fkDepartmentID);

			//setUserZusBereichList
			if (allebereiche.length > 0) {
				let filteredBereiche = departments.filter((item) =>
					allebereiche.includes(item.pkDepartmentID)
				);
				setUserZusBereichList(filteredBereiche);
			}

			allebereiche.push(loggedInUser.intMainDepartment);

			setFilteredPrompts(
				prompts.filter((item) =>
					users
						.filter((item) =>
							allebereiche.includes(item.intMainDepartment)
						)
						.map((item) => item.pkUserID)
						.includes(item.fkUserID)
				)
			);
		}
	}, [prompts, loggedInUser, userDepartments]);

	// Initialize shared data here

	useEffect(() => {
		const f = async () => {
			if (tags.length === 0) {
				callBackend({ action: "get_tags" })
					.then((res) => {
						setTags(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (promptTags.length === 0) {
				callBackend({ action: "get_prompt_tags" })
					.then((res) => {
						setPromptTags(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (kategorieList.length === 0) {
				callBackend({ action: "get_categories" })
					.then((res) => {
						setKategorieList(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (departments.length === 0) {
				callBackend({ action: "get_departments" })
					.then((res) => {
						setDepartments(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (firms.length === 0) {
				callBackend({ action: "get_firms" })
					.then((res) => {
						setFirms(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (languages.length === 0) {
				callBackend({ action: "get_languages" })
					.then((res) => {
						setLanguages(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (outputs.length === 0) {
				callBackend({ action: "get_outputs" })
					.then((res) => {
						setOutputs(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (platforms.length === 0) {
				callBackend({ action: "get_platforms" })
					.then((res) => {
						setPlatforms(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (prompts.length === 0) {
				callBackend({ action: "get_prompts" })
					.then((res) => {
						setPrompts(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (roles.length === 0) {
				callBackend({ action: "get_roles" })
					.then((res) => {
						setRoles(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (styles.length === 0) {
				callBackend({ action: "get_styles" })
					.then((res) => {
						setStyles(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (unterkategorieList.length === 0) {
				callBackend({ action: "get_subcategories" })
					.then((res) => {
						setUnterkategorieList(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (tasks.length === 0) {
				callBackend({ action: "get_tasks" })
					.then((res) => {
						setTasks(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (markiertePrompts.length === 0) {
				callBackend({ action: "get_prompt_bookmarks" })
					.then((res) => {
						setMarkiertePrompts(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (promptCategories.length === 0) {
				callBackend({ action: "get_prompt_categories" })
					.then((res) => {
						setPromptCategories(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (users.length === 0) {
				callBackend({ action: "get_users" })
					.then((res) => {
						setUsers(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (userDepartments.length === 0) {
				callBackend({ action: "get_user_departments" })
					.then((res) => {
						setUserDepartments(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (zipCodes.length === 0) {
				callBackend({ action: "get_zipcodes" })
					.then((res) => {
						setZipCodes(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
		};
		f();
	}, []);

	// useEffect(() => {
	// 	if (zipCodes) {
	// 		console.log(zipCodes);
	// 	}
	// }, [zipCodes]);

	return (
		<SharedDataContext.Provider
			value={{
				tags,
				setTags,
				prompts,
				filteredPrompts,
				setPrompts,
				markiertePrompts,
				setMarkiertePrompts,
				kategorieList,
				setKategorieList,
				unterkategorieList,
				setUnterkategorieList,
				loggedInUser,
				setLoggedInUser,
				error,
				handleLogin,
				handleLogout,
				departments,
				setDepartments,
				users,
				setUsers,
				fkCompanyID,
				firmusers,
				firms,
				setFirms,
				setFirmUsers,
				userZusBereichList,
				setUserZusBereichList,
				userDepartments,
				setUserDepartments,
				promptCategories,
				setPromptCategories,
				promptTags,
				setPromptTags,
				callBackend,
				promptData,
				setPromptData,
				languages,
				setLanguages,
				outputs,
				setOutputs,
				platforms,
				setPlatforms,
				roles,
				setRoles,
				styles,
				setStyles,
				tasks,
				setTasks,
				zipCodes,
				setZipCodes,
			}}
		>
			{children}
		</SharedDataContext.Provider>
	);
}

export function useSharedData() {
	return useContext(SharedDataContext);
}
