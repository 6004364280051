import LogoImage from "../images/Prompts Biblothek.png";
import { callBackend, useSharedData } from "../SharedDataContext";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const Login = () => {
	const navigate = useNavigate();
	const { handleLogin, users } = useSharedData();

	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	// Determine if the login button should be disabled
	const isButtonDisabled = !login || !password;

	const handleLoginClick = () => {
		callBackend({
			action: "login",
			payload: { strPassword: password, strUserName: login },
		})
			.then((rst) => {
				if (rst.serverAction === "login_success") {
					const user = users.find(
						(u) => u.pkUserID === Number(rst.pkUserID)
					);
					handleLogin(user);
					navigate("/erfassung");
				} else {
					setError("Ungültiger Login oder Passwort");
				}
			})
			.catch((err) => console.log(err));
	};
	const handleOhneLoginClick = () => {
		let login1 = "test";
		let password1 = "Montag.123";
		callBackend({
			action: "login",
			payload: { strPassword: password1, strUserName: login1 },
		})
			.then((rst) => {
				if (rst.serverAction === "login_success") {
					const user = users.find(
						(u) => u.pkUserID === Number(rst.pkUserID)
					);
					handleLogin(user);
					navigate("/erfassung");
				}
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className="LoginSeite">
			{/* <Header items={pageName} /> */}

			<div className="Logo_login">
				<img id="Logo" name="Logo" src={LogoImage} alt="" srcSet="" />
			</div>

			<section className="LoginForm">
				<h2>Login</h2>
				<form
					className="Form--log"
					action=""
					method="get"
					autoComplete="off"
				>
					<div>
						<label htmlFor="Benutzername">Benutzername:</label>
						<input
							id="Benutzername"
							name="Benutzername"
							type="text"
							placeholder="Login"
							autoComplete="username"
							value={login}
							onChange={(e) => setLogin(e.target.value)}
							required
						/>
					</div>

					<div>
						<label htmlFor="strPasswort"> Passwort:</label>
						<input
							id="Passwort"
							name="strPasswort"
							type="password"
							placeholder="Passwort"
							autoComplete="current-password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required
						/>
					</div>

					<div className="LoginButtonDiv">
						<button
							type="button"
							onClick={handleLoginClick}
							disabled={isButtonDisabled}
						>
							Anmelden
						</button>
						{/* <button type="button" onClick={handleOhneLoginClick}>
							Anmelden Ohne Login
						</button> */}

						<p style={{ color: "red" }}>{error}</p>
					</div>
				</form>
			</section>
		</div>
	);
};

export default Login;
