import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../SharedDataContext";
import LogoImage from "../images/logo_prompts.svg";
import { GrLogout } from "react-icons/gr";
import { GrLogin } from "react-icons/gr";
import Next from "../images/next.svg";

const Header = (props) => {
	const { loggedInUser, handleLogout } = useUser();
	const navigate = useNavigate();
	const [pagetitle, setPagetitle] = useState("PROMPT");

	useEffect(() => {
		if (loggedInUser) {
			let text =
				"Welcome  " +
				loggedInUser.strSurName +
				" " +
				loggedInUser.strFirstName +
				"!";
			setPagetitle(text);
		}
	}, []);

	const backButtonClick = () => {
		navigate("/ergebnis");
	};

	const homeButtonClick = () => {
		navigate("/");
	};

	return (
		<Fragment>
			<header>
				<div className="left">
					{props.showButton && (
						<button onClick={backButtonClick}>
							<img className="bookmark back" src={Next} /> zurück
						</button>
					)}
				</div>

				<div className="center">
					<div className="Logo-header">
						Prompts Bibliothek
						{/* <img
							id="Logo"
							name="Logo"
							src={LogoImage}
							alt=""
							srcSet=""
						/> */}
					</div>
				</div>
				<div className="right">
					{/* <div className="welcome-header">{pagetitle}</div> */}
					<button className="logButton" onClick={homeButtonClick}>
						{loggedInUser !== null ? (
							<div>
								<p>Abmelden</p>
								{/* <GrLogout /> */}
							</div>
						) : (
							<div>
								<p>Anmelden</p>
								<GrLogin />
							</div>
						)}
					</button>
				</div>
			</header>
		</Fragment>
	);
};

export default Header;
