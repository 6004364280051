const PasswordChangeInput = ({ label, name, value, onChange, placeholder }) => {
	return (
		<div>
			<input
				type="password"
				className="passwordInput"
				id={name}
				name={name}
				value={value}
				placeholder={placeholder}
				autoComplete={
					name === "currentPassword"
						? "current-password"
						: "new-password"
				}
				onChange={onChange}
			/>
		</div>
	);
};

export default PasswordChangeInput;
