import React from "react";
import { useState, useEffect } from "react";
import Header from "../component/Header";
import FirmInfo from "../component/FirmInfo";
import SeitenListe from "../component/SeitenListe";
import NeueBenutzer from "../component/NeueBenutzer";
import BenutzerInfo from "../component/BenutzerInfo";
import { useSharedData } from "../SharedDataContext";
import { useNavigate } from "react-router";
import BenutzerList from "../component/BenutzerList";

const Einstellung = () => {
	const [userId, setUserID] = useState(0);
	const navigate = useNavigate();
	const [firmId, setFirmId] = useState(null);

	// const dataCtx = useContext(useSharedData);
	const { loggedInUser } = useSharedData();

	useEffect(() => {
		if (!loggedInUser) {
			navigate("/");
		} else {
			setFirmId(loggedInUser.fkCompanyID);
			const userId = loggedInUser.pkUserID;
			setUserID(userId);
			setShowBlock(0);
		}
	}, []);
	const pageName = {
		name: " Einstellung",
		link1: "/",
		link2: "/",
		hide1: "yes",
		hide2: "no",
		userId: userId,
	};

	const [showBlock, setShowBlock] = useState(0);

	return (
		<div>
			<Header items={pageName} />
			<SeitenListe />
			<section className="Einstellungsseite">
				<div className="wrapper">
					<div className="SeiteForm">
						<ul>
							<li
								className={showBlock === 0 ? "active" : ""}
								onClick={() => setShowBlock(0)}
							>
								Personalinformationen
							</li>
							<li
								className={showBlock === 1 ? "active" : ""}
								onClick={() => setShowBlock(1)}
							>
								Firmendaten
							</li>
							{/* <li className={showBlock === 2 ? "active" : ""}>
								Benachrichtigung
							</li>
							<li className={showBlock === 3 ? "active" : ""}>
								Sprache
							</li>
							<li className={showBlock === 4 ? "active" : ""}>
								Hintergrundfarbe
							</li> */}
							{loggedInUser?.fkDataRoleID === 0 ? (
								<li
									className={showBlock === 6 ? "active" : ""}
									onClick={() => setShowBlock(6)}
								>
									Benutzerliste
								</li>
							) : (
								""
							)}
							{loggedInUser?.fkDataRoleID === 0 ? (
								<li
									className={showBlock[7] ? "active" : ""}
									onClick={() => setShowBlock(7)}
								>
									Neue Benutzer
								</li>
							) : (
								""
							)}

							<li onClick={() => navigate("/")}>
								Konto wechseln
							</li>
						</ul>
					</div>
					{loggedInUser && (
						<div className="HauptForm">
							{showBlock === 0 && <BenutzerInfo />}
							{showBlock === 1 && <FirmInfo firmId={firmId} />}
							{showBlock === 6 && <BenutzerList />}
							{showBlock === 7 && <NeueBenutzer />}
						</div>
					)}
				</div>
			</section>
		</div>
	);
};

export default Einstellung;
