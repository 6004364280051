import React, { useState, useRef, useEffect } from 'react'

const StandardChat = ({ onChatValuesChange, chatType }) => {
  const [formData, setFormData] = useState({
    role: '',
    task: '',
    thema: '',
    stil: '',
    emojis: '',
    länge: {
      min: '',
      max: '',
    },
    zielgruppe: '',
    callToAction: '',
    form: '',
    sprache: '',
    format: '',
    platform: '',
  })

  const [taskValue, setTaskValue] = useState('')
  const [selectedTaskOption, setSelectedTaskOption] =
    useState('-Bitte auswählen-')

  const handleInputChange2 = (e) => {
    setTaskValue(e.target.value)

    let taskTxt = ''
    if (selectedTaskOption === '-Bitte auswählen-') {
      taskTxt = e.target.value
    } else {
      taskTxt = selectedTaskOption + ' ' + e.target.value
    }
    const updatedFormData = { ...formData }
    updatedFormData.task = taskTxt
    setFormData(updatedFormData)

    onChatValuesChange(formData)
  }

  useEffect(() => {
    onChatValuesChange(formData)
  }, [formData])

  return (
    <div className='structuralChat'>
      {chatType === 'option1' && (
        <div>
          <label>Eingabe:</label>
          <input
            type='text'
            id='eingabeText'
            value={taskValue}
            placeholder='Prompt schreiben...'
            onChange={handleInputChange2}
          />
        </div>
      )}
    </div>
  )
}

export default StandardChat
