import React from 'react'
import Header from '../component/Header'
import SeitenListe from '../component/SeitenListe'
import DashboardChart from '../component/DashboardChart'
import DashboardTimelineChart from '../component/DashboardLine'
import DashboardDivision from '../component/DashboardDivision'

const Dashboard = () => {
  const userId = 123
  const pageName = {
    name: 'Dashboard',
    link1: '/',
    link2: '/',
    hide1: 'yes',
    hide2: 'no',
    userId: userId,
  }
  //Dashboardseite
  //Ergebnisseite
  return (
    <div>
        <Header items={pageName} />
        <SeitenListe />
        <section className='Ergebnisseite'>
        <div className='wrapper'>
              <div className='SeiteForm'>
                <p>Liste</p>
              </div>
              <div className='HauptForm'>
                    <div className='chart-background'><DashboardChart/></div>
                    <div className='chart-background'><DashboardTimelineChart/></div>
                    <div className='chart-background'><DashboardDivision/></div>
              </div>
          </div>
        </section>
    </div>
  )
}

export default Dashboard
