import React, { useState, useEffect } from "react";
import FilterInput from "./FilterInput";
import Checkbox from "./Checkbox";
import { useSharedData } from "../SharedDataContext";

const PromptFilter = (props) => {
	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(true);
	const [promptusers, setPromptusers] = useState([]);
	const [promptbereiche, setPromptbereiche] = useState([]);
	const { users, departments, tags, markiertePrompts, loggedInUser } =
		useSharedData();
	const [filteredprompts, setFilteredprompts] = useState(
		props.filteredprompts
	);
	const [filterText, setFilterText] = useState("");
	const [variableValues, setVariableValues] = useState({
		variable1: true,
		variable2: true,
		variable3: true,
	});
	useEffect(() => {
		const bereicheIDs = [];
		const userIDs = [];
		for (const k in props.filteredprompts) {
			if (userIDs.includes(props.filteredprompts[k].fkUserID)) {
			} else {
				userIDs.push(props.filteredprompts[k].fkUserID);
			}
		}
		for (let i = 0; i < userIDs.length; i++) {
			const number = userIDs[i];
			const user = users.find((item) => item.pkUserID === number);
			//find user's bereich
			if (user) {
				if (bereicheIDs.includes(user.intMainDepartment)) {
					//
				} else {
					bereicheIDs.push(user.intMainDepartment);
				}
			}
		}
		const userList = [];
		for (const id of userIDs) {
			const item = users.find((dataItem) => dataItem.pkUserID === id);
			if (item) {
				let name = "";
				name = item.strSurName + " " + item.strFirstName;
				userList.push({ id: id, userName: name, checked: false });
			}
		}
		userList.push({ id: 0, userName: "Alle", checked: true });
		const bereicheList = [];
		for (const id of bereicheIDs) {
			const item = departments.find(
				(dataItem) => dataItem.pkDepartmentID === id
			);
			if (item) {
				let name = "";
				name = item.strDepartment;
				bereicheList.push({
					id: id,
					bereichName: name,
					checked: false,
				});
			}
		}
		bereicheList.push({ id: 0, bereichName: "Alle", checked: true });
		setPromptbereiche(bereicheList);
		setPromptusers(userList);
	}, [props.filteredprompts]);

	const handleCheckbox1Change = () => {
		if (checkbox1 === false && checkbox3 === true) {
			setCheckbox3(false);
		}
		if (checkbox2 === false && checkbox1 === true) {
			setCheckbox3(true);
		}
		setCheckbox1(!checkbox1);
	};
	const handleCheckbox2Change = () => {
		if (checkbox2 === false && checkbox3 === true) {
			setCheckbox3(false);
		}
		if (checkbox2 === true && checkbox1 === false) {
			setCheckbox3(true);
		}
		setCheckbox2(!checkbox2);
	};

	const handleCheckbox3Change = () => {
		if (checkbox1 === true || checkbox2 === true) {
			setCheckbox1(false);
			setCheckbox2(false);
		}
		if (checkbox3 === false) {
			let filtereddata = props.filteredprompts;
		}
		setCheckbox3(!checkbox3);
	};
	function filterBereichHandler() {
		let filtereddata = props.filteredprompts;
		if (promptbereiche.length > 0) {
			let checkeddata = promptbereiche.filter(
				(item) => item.checked === true
			);
			const checkeddataIDs = [];
			for (const k in checkeddata) {
				checkeddataIDs.push(checkeddata[k].id);
			}
			if (checkeddata.length === 1) {
				if (checkeddata[0].id === 0) {
					//Alle
					filtereddata = props.filteredprompts;
				} else {
					const userList = users.filter((item) =>
						checkeddataIDs.includes(item.intMainDepartment)
					);
					const userIDs = [];
					for (const k in userList) {
						userIDs.push(userList[k].pkUserID);
					}
					filtereddata = props.filteredprompts.filter((item) =>
						userIDs.includes(item.fkUserID)
					);
				}
			} else {
				const userList = users.filter((item) =>
					checkeddataIDs.includes(item.intMainDepartment)
				);
				const userIDs = [];
				for (const k in userList) {
					userIDs.push(userList[k].pkUserID);
				}
				filtereddata = props.filteredprompts.filter((item) =>
					userIDs.includes(item.fkUserID)
				);
			}
		}
		return filtereddata;
	}
	function filterBenutzerHandler() {
		//filterMarkedHandler()
		let filtereddata = filteredprompts;
		if (promptusers.length > 0) {
			let checkeddata = promptusers.filter(
				(item) => item.checked === true
			);
			const checkeddataIDs = [];
			for (const k in checkeddata) {
				checkeddataIDs.push(checkeddata[k].id);
			}

			if (checkeddata.length === 1) {
				if (checkeddata[0].id === 0) {
					//Alle

					filtereddata = filteredprompts;
				} else {
					filtereddata = filteredprompts.filter((item) =>
						checkeddataIDs.includes(item.fkUserID)
					);
				}
			} else {
				filtereddata = filteredprompts.filter((item) =>
					checkeddataIDs.includes(item.fkUserID)
				);
			}
		}
		return filtereddata;
	}
	// filter for Marked
	function filterMarkedHandler() {
		let newprompts = filteredprompts;

		let filtereddata;
		if (checkbox1 === true && checkbox2 === false) {
			//get ids for marked
			const markeddata = markiertePrompts.filter(
				(item) => item.fkUserID === loggedInUser.pkUserID
			);
			const markeddataIDs = [];
			for (const k in markeddata) {
				markeddataIDs.push(markeddata[k].fkPromptID);
			}
			//prompts
			filtereddata = newprompts.filter((item) =>
				markeddataIDs.includes(item.pkPromptID)
			);
		}

		if (checkbox1 === false && checkbox2 === true) {
			//get ids for marked
			const markeddata = markiertePrompts.filter(
				(item) => item.fkUserID === loggedInUser.pkUserID
			);
			const markeddataIDs = [];
			for (const k in markeddata) {
				markeddataIDs.push(markeddata[k].fkPromptID);
			}

			filtereddata = newprompts.filter(
				(item) => !markeddataIDs.includes(item.pkPromptID)
			);
		}
		if ((checkbox1 === true && checkbox2 === true) || checkbox3 === true) {
			filtereddata = newprompts; //
		}
		return filtereddata;
	}

	function filterInputTextHandler(value) {
		let filtereddata = filteredprompts;
		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			item.txtImput.toLowerCase().includes(value.toLowerCase())
		);

		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);
		return pkPromptIDs;
	}

	function filterOutputTextHandler(value) {
		let filtereddata = filteredprompts;
		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			item.txtOutput.toLowerCase().includes(value.toLowerCase())
		);

		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);

		return pkPromptIDs;
	}

	function filterCommentTextHandler(value) {
		let filtereddata = filteredprompts;
		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			item.txtComment.toLowerCase().includes(value.toLowerCase())
		);

		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);

		return pkPromptIDs;
	}

	useEffect(() => {
		let filtereddata;

		let filteredMarked = filterMarkedHandler();
		let filteredBereiche = filterBereichHandler();
		let filteredBenutzer = filterBenutzerHandler();

		const lfdPromptIds1 = filteredMarked.map((item) => item.pkPromptID);
		const lfdPromptIds2 = filteredBereiche.map((item) => item.pkPromptID);
		const lfdPromptIds3 = filteredBenutzer.map((item) => item.pkPromptID);
		const commonIDs = [
			...new Set(
				lfdPromptIds1.filter((id) => lfdPromptIds2.includes(id))
			),
		];

		if (commonIDs && commonIDs.length === 0) {
			filtereddata = [];
		} else {
			const commonIDs2 = [
				...new Set(
					lfdPromptIds3.filter((id) => commonIDs.includes(id))
				),
			];

			filtereddata = filteredprompts.filter((item) =>
				commonIDs2.includes(item.pkPromptID)
			);

			if (commonIDs2 && commonIDs2.length === 0) {
			} else if (filterText != "") {
				const set1 = variableValues.variable1
					? new Set(filterInputTextHandler(filterText))
					: [];
				const set2 = variableValues.variable2
					? new Set(filterOutputTextHandler(filterText))
					: [];
				const set3 = variableValues.variable3
					? new Set(filterCommentTextHandler(filterText))
					: [];

				// Merge all arrays and convert to a Set and back to an array to return the list of unique IDs
				// Filter according to the remaining filter selections
				const commonIDs3 = [
					...new Set([...set1, ...set2, ...set3]),
				].filter((id) => commonIDs2.includes(id));

				filtereddata = filteredprompts.filter((item) =>
					commonIDs3.includes(item.pkPromptID)
				);
			}
		}

		//let newdata=filterMarkedHandler()

		let allebereiche = false;
		let allebenutzer = false;
		if (promptbereiche.length > 0) {
			let checkeddata = promptbereiche.filter(
				(item) => item.checked === true
			);
			if (checkeddata.length === 1 && checkeddata[0].id === 0) {
				//alle bereiche
				allebereiche = true;
			}
		}

		if (promptusers.length > 0) {
			let checkeddata = promptusers.filter(
				(item) => item.checked === true
			);
			if (checkeddata.length === 1 && checkeddata[0].id === 0) {
				//alle bereiche
				allebenutzer = true;
			}
		}

		if (allebereiche === true && allebenutzer === true) {
		} else {
		}
		props.setFilteredprompts(filtereddata);
	}, [
		checkbox1,
		checkbox2,
		checkbox3,
		filterText,
		promptusers,
		promptbereiche,
		variableValues,
	]);

	const handleUserChange = (item) => {
		const id = item.id;

		const updatedArray = promptusers.map((item) => {
			if (item.id === id) {
				return { ...item, checked: !item.checked };
			}
			return item;
		});
		// Find the index of the item with id=0
		if (id === 0) {
			const index = updatedArray.findIndex((item) => item.id === 0);
			if (updatedArray[index].checked) {
				updatedArray.forEach((item) => {
					if (item.id !== 0) {
						item.checked = false;
					}
				});
			}
		} else {
			const indexid = updatedArray.findIndex((item) => item.id === id);
			if (updatedArray[indexid].checked) {
				updatedArray.forEach((item) => {
					if (item.id === 0) {
						item.checked = false;
					}
				});
			}
		}

		const checkedItems = updatedArray.filter(
			(item) => item.checked === true
		);
		if (checkedItems.length === 0) {
			updatedArray.forEach((item) => {
				if (item.id === 0) {
					item.checked = true;
				}
			});
		}

		setPromptusers(updatedArray);
	};

	const handleBereichChange = (item) => {
		const id = item.id;

		const updatedArray = promptbereiche.map((item) => {
			if (item.id === id) {
				return { ...item, checked: !item.checked };
			}
			return item;
		});
		// Find the index of the item with id=0
		if (id === 0) {
			const index = updatedArray.findIndex((item) => item.id === 0);
			if (updatedArray[index].checked) {
				updatedArray.forEach((item) => {
					if (item.id !== 0) {
						item.checked = false;
					}
				});
			}
		} else {
			const indexid = updatedArray.findIndex((item) => item.id === id);
			if (updatedArray[indexid].checked) {
				updatedArray.forEach((item) => {
					if (item.id === 0) {
						item.checked = false;
					}
				});
			}
		}

		const checkedItems = updatedArray.filter(
			(item) => item.checked === true
		);
		if (checkedItems.length === 0) {
			updatedArray.forEach((item) => {
				if (item.id === 0) {
					item.checked = true;
				}
			});
		}

		setPromptbereiche(updatedArray);
	};

	const handleVariableChange = (variableName, value) => {
		setVariableValues((prevValues) => ({
			...prevValues,
			[variableName]: value,
		}));
	};

	return (
		<div className="search">
			<FilterInput
				filterText={filterText}
				onFilterChange={setFilterText}
				variableValues={variableValues}
				onVariableChange={handleVariableChange}
			/>
			<br />
			<br />
			<div className="filterBereiche">
				<h3>Markierte:</h3>

				<Checkbox
					label="Markierte"
					checked={checkbox1}
					onChange={handleCheckbox1Change}
				/>
				<Checkbox
					label="Nicht Markierte"
					checked={checkbox2}
					onChange={handleCheckbox2Change}
				/>
				<Checkbox
					label="Alle"
					checked={checkbox3}
					onChange={handleCheckbox3Change}
				/>
			</div>
			<br />
			<br />
			<div className="filterBereiche">
				<h3>Bereiche:</h3>
				{promptbereiche.map((item, index) => (
					<Checkbox
						key={index}
						label={item.bereichName}
						checked={item.checked}
						onChange={handleBereichChange.bind(null, item)}
					/>
				))}
			</div>
			<br />
			<br />

			<div className="filterBereiche">
				<h3>Benutzer:</h3>
				{promptusers.map((item, index) => (
					<Checkbox
						key={index}
						checked={item.checked}
						label={item.userName}
						onChange={handleUserChange.bind(null, item)}
					/>
				))}
			</div>
			{/* <br />
			<br />

			<div className="filterTags">
				<h3>Tags:</h3>
				{tags.map((item, index) => (
					<Checkbox
						key={index}
						checked={item.checked}
						label={item.strDataPromptTag}
						// onChange={handleTagChange.bind(null, item)}
					/>
				))}
			</div> */}
		</div>
	);
};

export default PromptFilter;
