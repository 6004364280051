import React from "react";

const PropertiesInput = ({
	label,
	options,
	optionID,
	textID,
	inputValue,
	selectedOption,
	onInputChange,
	onSelectChange,
	chatType,
}) => {
	let placeholderText = "Beschreiben ";

	return (
		<div className="chat-row-container">
			<label>{label}</label>
			{options && options.length > 0 && (
				<div>
					<select value={selectedOption} id={optionID} onChange={onSelectChange}>
						<option value={0}>-Bitte auswählen-</option>
						{options.map((optionObj) => (
							<option key={`${optionID}_${optionObj.id}`} value={optionObj.id}>
								{optionObj.option}
							</option>
						))}
					</select>
				</div>
			)}
			{chatType === "option3" && (
				<input
					type="text"
					id={textID}
					placeholder={placeholderText}
					onChange={onInputChange}
					value={inputValue}
				/>
			)}
		</div>
	);
};

export default PropertiesInput;