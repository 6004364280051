import React, { useState, useEffect  } from 'react';
import { Line } from 'react-chartjs-2';
import { useSharedData } from '../SharedDataContext'
import 'chartjs-adapter-date-fns';
import { Co2Sharp } from '@mui/icons-material';

const DashboardTimelineChart = (props) => {
    const {filteredPrompts }=useSharedData()
    const [data, setData] = useState(filteredPrompts);

//{ label, checked, onChange }
useEffect(()=>{
  // Count occurrences of each date
  if (filteredPrompts.length > 0) {
  const dateCount = filteredPrompts.reduce((acc, entry) => {
    acc[entry.datCreate.split(' ')[0]] = (acc[entry.datCreate.split(' ')[0]] || 0) + 1;
    return acc;
  }, {});
   // Sort dates chronologically
   const sortedDates = Object.entries(dateCount).sort((a, b) => {
    return new Date(a[0]) - new Date(b[0]);
  });
  // Extract dates and counts for plotting
  const dates = sortedDates.map(([date]) => date);
  const counts = sortedDates.map(([, count]) => count);
  // Create the chart data
  setData({
    labels: dates,
    datasets: [
      {
        label: 'Prompts Anzahl',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: counts,
      },
    ],
  });
  }
},[filteredPrompts])
return (
  <div className='chart1'>
    <h2>Historisches Zeitleistendiagramm</h2>
    {data.labels && data.labels.length > 0 ? (
      <div >
        <Line
          data={data}
          options={{
            maintainAspectRatio: false,
            scales: {
                x: {
                  type: 'time',
                  time: {
                    tooltipFormat:"yyyy-MM-dd",
                    unit: 'day',
                    displayFormats: {
                        day: 'yyyy-MM-dd', // Use 'yyyy' instead of 'YYYY' for the year
                    },
                  },
                },
                y: {
                  beginAtZero: true,
                  precision: 0,
                },
              },
          }}
        />
      </div>
      ) : (
        <p>Loading chart...</p>
      )}
  </div>
  );
};
    
export default DashboardTimelineChart;
    