import React, { useState } from "react";
import FirmInfoItem from "./FirmInfoItem";
import { useEffect } from "react";
import { useSharedData } from "../SharedDataContext";
import BereicheList from "./BereicheList";

const FirmInfo = ({ firmId }) => {
	const { firms, loggedInUser, zipCodes } = useSharedData();
	const [name, setName] = useState("");
	const [strasse, setStrasse] = useState("");
	const [plzID, setPLZID] = useState("");
	const [url, setURL] = useState("");

	useEffect(() => {
		if (firms) {
			const filteredFirm = firms.filter(
				(uk) => uk.pkCompanyID === firmId
			);

			if (filteredFirm.length > 0) {
				setName(filteredFirm[0].strCompanyName);
				setStrasse(filteredFirm[0].strStreet);
				setPLZID(filteredFirm[0].pkDataZipCodeID);
				setURL(filteredFirm[0].strWebsite);
			}
		}
	}, []);

	return (
		<div>
			<div>
				<h2>Firmeninformation</h2>
				{name !== "" && (
					<table>
						<tbody>
							<FirmInfoItem
								label="Name"
								value={name}
								strEditType="text"
								strValueID="strCompanyName"
							/>
							<FirmInfoItem
								label="Strasse"
								value={strasse}
								strEditType="text"
								strValueID="strStreet"
							/>
							<FirmInfoItem
								label="Ort"
								value={plzID}
								strEditType="select"
								arrOptions={zipCodes
									.filter((obj) => obj.pkDataZipCodeID !== 0)
									.map((obj) => ({
										value: obj.pkDataZipCodeID,
										name: `${obj.strZipCode} ${obj.strCity}`,
									}))}
							/>
							<FirmInfoItem
								label="Branch"
								strEditType="text"
								value="Technology"
							/>
							{/*??*/}
							<FirmInfoItem
								label="Website"
								strEditType="link"
								value={url}
							/>
						</tbody>
					</table>
				)}
			</div>
			<div>
				<h2>Bereiche</h2>
				{<BereicheList />}
			</div>
		</div>
	);
};

export default FirmInfo;
