import React, { useState } from 'react'
import UnterkategorieForm from './UnterkategorieForm'
import { AiFillPlusSquare } from 'react-icons/ai'

const NeueUnterkategorie = (props) => {
  const [isEditing, setIsEditing] = useState(false)

  const saveDataHandler = (neueUnterkategorie) => {
    props.onAddUnterkategorie(neueUnterkategorie)
    setIsEditing(false)
  }

  const startEditingHandler = () => {
    setIsEditing(true)
  }

  const stopEditingHandler = () => {
    setIsEditing(false)
  }

  return (
    <div>
      {!isEditing && (
        <AiFillPlusSquare onClick={startEditingHandler} className='addButton' />
      )}
      {isEditing && (
        <UnterkategorieForm
          onSaveData={saveDataHandler}
          onCancel={stopEditingHandler}
          ausgewaehlteKategorie={props.ausgewaehlteKategorie}
          kategorieList={props.kategorieList}
          unterkategorieList={props.unterkategorieList}
        />
      )}
    </div>
  )
}

export default NeueUnterkategorie
