import React, { useState } from 'react';

const Checkbox = (props) => {

	//{ label, checked, onChange }
	const handleChange = (event) => {
		console.log(event.target.checked)
		console.log(props.label)
	}
	return (
		<div>
			<label>
				<input
					type="checkbox"
					checked={props.checked}
					onChange={props.onChange}
				/>
				{props.label}
			</label>

		</div>
	);
};

export default Checkbox;
