import PromptItem from "./PromptItem";
import { useState, useEffect } from "react";
import { useSharedData } from "../SharedDataContext";

const PromptList = (props) => {
	const [sortedByDate, setSortedByDate] = useState(false);
	const [len, setLen] = useState(0);
	const { prompts, setPrompts, users } = useSharedData();
	const sortByDate = () => {
		const sortedData = [...prompts];
		if (sortedByDate) {
			sortedData.sort((a, b) => new Date(b.Date) - new Date(a.Date));
		} else {
			sortedData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		}
		// sortedData.sort((a, b) => {
		//   const dateA = new Date(a.date);
		//   const dateB = new Date(b.date);
		//   return sortedByDate ? dateA - dateB : dateB - dateA;
		// });
		setPrompts(sortedData);
		setSortedByDate(!sortedByDate);
	};

	useEffect(() => {
		if (props.filteredprompts && props.filteredprompts.length > 0) {
			setLen(1);
		}
	}, []);

	useEffect(() => {
		if (props.filteredprompts && props.filteredprompts.length > 0) {
			setLen(1);
		}
	}, [props.filteredprompts]);

	return (
		<div>
			{/* Prompts SwipeableList*/}
			{props.filteredprompts.length === 0 && <div> keine Daten </div>}
			{props.filteredprompts.map((item, index) => (
				<PromptItem
					index={index}
					key={`prompt_${item.pkPromptID}`}
					id={item.pkPromptID}
					input={item.txtImput}
					output={item.txtOutput}
					name={item.strPromptName}
					comment={item.txtComment}
					type={item.Type}
					fkUserID={item.fkUserID}
					date={item.datCreate}
					// eigenschaftIDs={item.EigenschaftIDs}
					kategorieList={props.kategorieList}
					unterkategorieList={props.unterkategorieList}
					tagIDs={item.TagIDs}
					setTags={props.setTags}
					loggedInUser={props.loggedInUser}
				/>
			))}
		</div>
	);
};

export default PromptList;
