import React, { Fragment, useEffect, useState } from "react";
import { callBackend, useUser } from "../SharedDataContext";
import { useSharedData } from "../SharedDataContext";

const FirmInfoItem = (props) => {
	const { value, label, strEditType, strValueID, arrOptions } = props;
	const [isEditing, setIsEditing] = useState(false);
	const [editedValue, setEditedValue] = useState(value);
	const { loggedInUser } = useUser();
	const { firms, setFirms } = useSharedData();
	const options = Array.isArray(arrOptions) ? arrOptions : [];

	const displayValue =
		strEditType === "select"
			? options.find((option) => option.value === value)?.name
			: strEditType === "multiselect"
			? options
					.filter((option) => value.includes(option.value))
					.map((option) => option.name)
					.join(", ")
			: value;

	const handleEditClick = () => {
		if (isEditing) {
			callBackend({
				action: "update_firm",
				payload: {
					pkCompanyID: loggedInUser.fkCompanyID,
					[strValueID]: editedValue,
				},
			}).catch((err) => console.log(err));
			setFirms(
				firms.map((item) =>
					item.pkCompanyID === loggedInUser.fkCompanyID
						? {
								...item,
								[strValueID]: editedValue,
						  }
						: item
				)
			);
		}
		setIsEditing(!isEditing);
	};

	const handleInputChange = (e) => {
		setEditedValue(e.target.value);
	};

	const handleCheckboxSelect = (e) => {
		if (e.target.checked) {
			setEditedValue([...editedValue, Number(e.target.value)]);
		} else {
			setEditedValue(
				editedValue.filter((val) => val !== Number(e.target.value))
			);
		}
	};

	const objEditTypes = {
		link: (
			<input
				type="text"
				value={editedValue}
				onChange={handleInputChange}
			/>
		),
		multiselect: (
			<span className="selectSpan">
				<div className="selectSpanDiv">
					{options.map((option, key) => (
						<Fragment key={`${strValueID}_${key}`}>
							<input
								type="checkbox"
								className="BereichCheckBox"
								name={`${strValueID}_${key}`}
								value={option.value}
								onChange={handleCheckboxSelect}
								checked={
									Array.isArray(value)
										? editedValue.includes(option.value)
										: false
								}
							/>
							<label htmlFor={`${strValueID}_${key}`}>
								{option.name}
							</label>
						</Fragment>
					))}
				</div>
			</span>
		),
		select: (
			<select
				name={strValueID}
				onChange={handleInputChange}
				value={editedValue}
			>
				{options.map((opt, key) => (
					<option key={`${strValueID}_${key}`} value={opt.value}>
						{opt.name}
					</option>
				))}
			</select>
		),
		text: (
			<input
				type="text"
				value={editedValue}
				onChange={handleInputChange}
			/>
		),
	};

	return (
		<tr>
			<td className="td1">{label}</td>
			<td className="td2">
				<div>
					{isEditing ? (
						objEditTypes[strEditType]
					) : strEditType === "link" ? (
						<span>
							<a href={editedValue || value}>{displayValue}</a>
						</span>
					) : (
						<span>{displayValue}</span>
					)}
					{strEditType === "disabled" ? (
						<div
							style={{
								display: "inline-block",
								width: "1px",
								height: "35px",
							}}
						/>
					) : (
						loggedInUser.fkDataRoleID === 0 && (
							<button onClick={handleEditClick}>
								{isEditing ? "Speichern" : "Ändern"}
							</button>
						)
					)}
				</div>
			</td>
		</tr>
	);
};

export default FirmInfoItem;
