import React, { useState } from 'react'
import KategorieForm from './KategorieForm'
import { AiFillPlusSquare } from 'react-icons/ai'

const NeueKategorie = (props) => {
  const [isEditing, setIsEditing] = useState(false)

  const saveDataHandler = (neueKategorie) => {
    props.onAddKategorie(neueKategorie)
    setIsEditing(false)
  }

  const startEditingHandler = () => {
    setIsEditing(true)
  }

  const stopEditingHandler = () => {
    setIsEditing(false)
  }

  return (
    <div>
      {!isEditing && (
        <AiFillPlusSquare onClick={startEditingHandler} className='addButton' />
      )}
      {isEditing && (
        <KategorieForm
          onSaveData={saveDataHandler}
          onCancel={stopEditingHandler}
          kategorieList={props.kategorieList}
        />
      )}
    </div>
  )
}

export default NeueKategorie
