import { Fragment } from 'react'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

const SeitenListe = () => {
  const [activePage, setActivePage] = useState(null)
  const location = useLocation()

  const pageList = [
    { name: 'Dashboard', link: '/dashboard' },
    { name: 'Erfassung', link: '/erfassung' },
    { name: 'Such- & Ergebnis', link: '/ergebnis' },
    { name: 'Einstellung', link: '/einstellung' },
  ]

  const handlePageClick = (page) => {
    setActivePage(page)
  }
  //const currentPage = location.pathname.split('/')[1];
  const currentPage = `${location.pathname.split('/')[1]}`
  // <Link to={item.link}>{item.name}</Link>
  const list = pageList.map((item) => (
    <Link key={item.name} to={item.link}>
      <li
        key={item.name}
        onClick={() => handlePageClick(item.name)}
        // className={activePage === item.name ? 'active' : ''}
        className={
          item.name.toLowerCase().includes(currentPage) ? 'active' : ''
        }
      >
        {item.name}
      </li>
    </Link>
  ))
  return (
    <Fragment>
      <nav>
        <ul className='navigation-list'>{list}</ul>
      </nav>
    </Fragment>
  )
}
export default SeitenListe
