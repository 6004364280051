import React from 'react'
import { useState, useEffect } from 'react'
import Header from '../component/Header'
import PromptFilter from '../component/PropmtFilter'
import PromptList from '../component/PromptList'
import SeitenListe from '../component/SeitenListe'
import PromptItemDetails from '../component/PromptItemDetails'
import { useNavigate } from 'react-router'
import { useSharedData } from '../SharedDataContext'



import { useLocation } from 'react-router-dom'

const Ergebnis = () => {
  const { tags, setTags } = useSharedData()
  const { filteredPrompts } = useSharedData()
  const { kategorieList, loggedInUser } = useSharedData()
  const { unterkategorieList } = useSharedData()
  const [filteredprompts, setFilteredprompts] = useState(filteredPrompts)

  const location = useLocation()
  const navigate = useNavigate()
  
  //Filter by Firm

  useEffect(() => {
    if (!loggedInUser) {
      navigate('/');
    }
  }, [])
  
  const [showButton, setShowButton] = useState(false)

  //Get the list of all users# ids

  useEffect(() => {
    setShowButton(location.pathname === '/ergebnis' ? false : true)
  }, [location])

  return (
    <div>
      <Header showButton={showButton} />
      <SeitenListe />
      <section className='Ergebnisseite'>
        {location.pathname === '/ergebnis' ? (
          <div className='wrapper'>
            <div className='SeiteForm'>
              <PromptFilter 
              filteredprompts={filteredPrompts}
              setFilteredprompts={setFilteredprompts}
              />
            </div>
            <div className='HauptForm'>
              <PromptList
                filteredprompts={filteredprompts}
                kategorieList={kategorieList}
                unterkategorieList={unterkategorieList}
                tags={tags}
                setTags={setTags}
                loggedInUser={loggedInUser}
              />
            </div>
          </div>
        ) : (
          <PromptItemDetails loggedInUser={loggedInUser} />
        )}
      </section>
    </div>
  )
}

export default Ergebnis
