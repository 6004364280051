import React from 'react'

const ChatArtDropdown = ({ selectedArt, handleArtChange, disabled }) => {
  return (
    <div className='SuchTyp'>
      <label>Suchtyp:</label>
      <select value={selectedArt} onChange={handleArtChange}   disabled={disabled}>
        <option value='art1'>Text</option>
        <option value='art2'>Bild</option>
        <option value='art3'>Video</option>
      </select>
    </div>
  )
}

export default ChatArtDropdown
