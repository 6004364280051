import React, { Fragment, useState } from "react";

import { useSharedData } from "../SharedDataContext";

const BenutzerInfoItem = (props) => {
	const { arrOptions, strEditType, strValueID, value, label, userID } = props;
	const [isEditing, setIsEditing] = useState(false);
	const [editedValue, setEditedValue] = useState(value);

	const {
		users,
		setUsers,
		setLoggedInUser,
		userDepartments,
		setUserDepartments,
		callBackend,
	} = useSharedData();
	const options = Array.isArray(arrOptions) ? arrOptions : [];

	const handleEditClick = () => {
		if (isEditing) {
			if (strValueID === "strUserName" && users.find((user) => user.strUserName === editedValue && user.pkUserID !== userID)) {
				alert("Login bereits vergeben!");
				return;
			}
			if (strEditType !== "multiselect") {
				const formattedValue = /^(pk|fk|int)/.test(strValueID)
					? Number(editedValue)
					: editedValue;

				callBackend({
					action: "update_user",
					payload: {
						pkUserID: userID,
						[strValueID]: formattedValue,
					},
				}).catch((err) => console.log(err));

				setUsers(
					users.map((user) =>
						user.pkUserID === userID
							? { ...user, [strValueID]: formattedValue }
							: user
					)
				);
				setLoggedInUser((prior) => ({
					...prior,
					[strValueID]: formattedValue,
				}));
			} else {
				const arrDel = userDepartments
					.filter(
						(obj) =>
							!editedValue.includes(obj.fkDepartmentID) &&
							obj.fkUserID === userID
					)
					.map((obj) => obj.fkDepartmentID);
				const arrAdd = editedValue.filter(
					(obj) =>
						!userDepartments
							.filter((entry) => entry.fkUserID === userID)
							.map((entry) => entry.fkDepartmentID)
							.includes(obj)
				);

				arrDel.forEach((departmentID) =>
					callBackend({
						action: "delete_user_department",
						payload: {
							fkDepartmentID: departmentID,
							fkUserID: userID,
						},
					}).catch((err) => console.log(err))
				);
				arrAdd.forEach((departmentID) =>
					callBackend({
						action: "save_user_department",
						payload: {
							fkDepartmentID: departmentID,
							fkUserID: userID,
						},
					}).catch((err) => console.log(err))
				);

				setUserDepartments((prior) => {
					let arrRet = prior.filter(
						(obj) =>
							editedValue.includes(obj.fkDepartmentID) ||
							obj.fkUserID !== userID
					);

					return [
						...arrRet,
						...arrAdd.map((obj) => ({
							fkDepartmentID: obj,
							fkUserID: userID,
						})),
					];
				});
			}
		}
		setIsEditing(!isEditing);
	};

	const handleInputChange = (e) => {
		setEditedValue(e.target.value);
	};

	const handleCheckboxSelect = (e) => {
		if (e.target.checked) {
			setEditedValue([...editedValue, Number(e.target.value)]);
		} else {
			setEditedValue(
				editedValue.filter((val) => val !== Number(e.target.value))
			);
		}
	};

	const objEditTypes = {
		multiselect: (
			<span className="selectSpan">
				<div className="selectSpanDiv">
					{options.map((option, key) => (
						<Fragment key={`${strValueID}_${key}`}>
							<input
								type="checkbox"
								className="BereichCheckBox"
								name={`${strValueID}_${key}`}
								value={option.value}
								onChange={handleCheckboxSelect}
								checked={
									Array.isArray(value)
										? editedValue.includes(option.value)
										: false
								}
							/>
							<label htmlFor={`${strValueID}_${key}`}>
								{option.name}
							</label>
						</Fragment>
					))}
				</div>
			</span>
		),
		select: (
			<select
				name={strValueID}
				onChange={handleInputChange}
				value={editedValue}
			>
				{options.map((opt, key) => (
					<option key={`${strValueID}_${key}`} value={opt.value}>
						{opt.name}
					</option>
				))}
			</select>
		),
		text: (
			<input
				type="text"
				value={editedValue}
				onChange={handleInputChange}
			/>
		),
	};

	const displayValue =
		strEditType === "select"
			? options.find((option) => option.value === value)?.name
			: strEditType === "multiselect"
			? options
					.filter((option) => value.includes(option.value))
					.map((option) => option.name)
					.join(", ")
			: value;

	return (
		<tr>
			<td className="td1">{label}</td>
			<td className="td2">
				<div>
					<div>
						{isEditing ? (
							objEditTypes[strEditType]
						) : (
							<span>{displayValue}</span>
						)}
						{strEditType === "disabled" ? (
							<div 
								style={{
									display: "inline-block",
									width: "1px",
									height: "35px",
								}}
							/>
						) : (
							<button onClick={handleEditClick}>
								{isEditing ? "Speichern" : "Ändern"}
							</button>
						)}
					</div>
				</div>
			</td>
		</tr>
	);
};

export default BenutzerInfoItem;
