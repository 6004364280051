import React, { useState, useRef, useEffect, useContext } from 'react'
import { FaRegCopy } from 'react-icons/fa'
import StructuralChat from './StructuralChat'
import ChatAssistant from './ChatAssistent'
import StandardChat from './StandardChat'

const ChatComponent = ({
  bemerkungWert,
  setBemerkungWert,
  ergebnisWert,
  setErgebnisWert,
  chatType,

  showBlock1,
  showBlock2,
  showBlock3,
  showBlock4,
}) => {
  const promptRef = useRef(null)
  const promptOutputRef = useRef(null)
  const [isPopupVisible1, setPopupVisible1] = useState(false)
  const [inputText, setInputText] = useState('')
  const [outputDisabled, setOutputDisabled] = useState(true)
  const [placeholderOutputText, setPlaceholderOutputText] = useState('')
  const [textString, setTextString] = useState('')
  const [chatHistory, setChatHistory] = useState([])
  const [regenerateVisible, setRegenerateVisible] = useState(false)
  // const { promptData, setPromptData } = useContext()

  const handleInputChange = (event) => {
    setInputText(event.target.value)
  }

  const handleOutputChange=(event)=>{
    setErgebnisWert(event.target.value)
    // console.log(outputText)
  }

  useEffect(() => {
    setInputText('')
    setRegenerateVisible(false)
    setErgebnisWert('')
  }, [chatType])

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!textString) return

    const newMessage = {
      text: textString,
      sender: 'user',
    }
    setInputText(textString)
    setOutputDisabled(false)
    setChatHistory([...chatHistory, newMessage])
    //setInputText('');

    // Simulate a response from an AI model (you can replace this with actual API calls)
    const responseMessage = {
      text: 'This is a response from ChatGPT.',
      sender: 'bot',
    }
    setChatHistory([...chatHistory, responseMessage])
    //setOutputText(responseMessage.text)
    setPlaceholderOutputText('Kopieren Sie das Ergebnis in dieses Textfeld')
    //setOutputText()
    //setRegenerateVisible(true)
    setBemerkungWert(inputText)
  }

  const handleRegenerate = () => {
    // Simulate regenerating the output (you can replace this with custom logic)
   // setOutputText('Regenerated Output: ' + Math.random())
  }

  const handleAssistantValuesChange = (values) => {
    setTimeout(() => {
      setInputText('Test')
    }, 1000)
  }

  // Function to handle changes in the StructuralChat component

  const handleChatValuesChange = (values) => {
    setTimeout(() => {
      setInputText('')
      setOutputDisabled(true)
      setErgebnisWert('')
      // Construct a text string using the received values
      const {
        role,
        task,
        thema,
        stil,
        emojis,
        länge,
        zielgruppe,
        callToAction,
        form,
        sprache,
        format,
        platform,
      } = values
      // const text = `Thema: ${thema}, Stil: ${stil}, Emojis: ${emojis}, Länge (min): ${länge.min}, Länge (max): ${länge.max}, Zielgruppe: ${zielgruppe}, Call to Action: ${callToAction}, Form: ${form}, Sprache: ${sprache}`
      let structuredPrompt = `Schreibe als ${role} ${task} über das Thema ${thema}.`
      if (format.length > 0) {
        structuredPrompt = structuredPrompt + ` Verwende hier das Format ${format}.`
      }
      if (platform.length > 0) {
        structuredPrompt =
          structuredPrompt + ` Der Text wird für die Plattform ${platform} geschrieben und Verwendet.`
      }
      if (zielgruppe.length > 0) {
        structuredPrompt =
          structuredPrompt + ` Es soll die Zielgruppe ${zielgruppe} ansprechen.`
      }
      if (stil.length > 0) {
        let styletxt = 'formell'
        if (stil === '2') {
          styletxt = 'informell'
        }
        structuredPrompt =
          structuredPrompt + ` Es soll ${styletxt} geschrieben sein.`
      }
      if (callToAction.length > 0) {
        structuredPrompt =
          structuredPrompt + ` Füge einen Call-to-Action hinzu: ${callToAction}.`
      }
      if (länge.min > 0) {
        structuredPrompt =
          structuredPrompt + ` Der Text muss mindestens ${länge.min} Wörtern verwenden`
        if (länge.max > 0) {
          structuredPrompt =
            structuredPrompt + ` und maximal ${länge.max} Wörter haben.`
        }else{
          structuredPrompt =
            structuredPrompt + `.`
        }
      } else {
        if (länge.max.length > 0) {
          structuredPrompt =
            structuredPrompt + ` Der Text darf maximal ${länge.max} Wörter haben.`
        }
      }
      if (emojis.length > 0) {
        structuredPrompt = structuredPrompt + ` Verwende für diese Prompt ${emojis} Emoji.`
      }

      if (sprache.length > 0) {
        structuredPrompt = structuredPrompt + ` Verfasse den Text auf ${sprache}.`
      }
      if(showBlock1){
        structuredPrompt = `${task}`
      }
      // Update the textString state with the constructed text
      if (task.length > 0 && ((role.length > 0 && thema.length > 0)|| showBlock1)) {
        setTextString(structuredPrompt)
      } else {
        setTextString('')
      }
    }, 1000)
  }

  const handleCopyPrompt = () => {
    // const textToCopy = promptRef.current.value
    // navigator.clipboard.writeText(textToCopy)

    const range = document.createRange()
    range.selectNode(promptRef.current)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)

    try {
      document.execCommand('copy')
      // You can provide feedback here, e.g., show a success message
      // Show the "kopiert erfolgreich" popup for a brief moment
      setPopupVisible1(true)
      setTimeout(() => {
        setPopupVisible1(false)
      }, 2000) // Adjust the timeout as needed (2 seconds in this example)
    } catch (error) {
      // Handle any errors that may occur during copying
      console.error('Copy failed:', error)
      // You can also provide feedback in case of an error
    } finally {
      window.getSelection().removeAllRanges()
    }
  }


  return (
    <div className='chat-container'>
      <form onSubmit={handleSubmit} className='input-form'>
        <div className='row1'>
          {showBlock1 && (
            <div>
              <StandardChat 
                onChatValuesChange={handleChatValuesChange}
                chatType={chatType}
              />
            </div>
          )}
          {showBlock2 && (
            <div>
              <StructuralChat
                onChatValuesChange={handleChatValuesChange}
                chatType={chatType}
              />
            </div>
          )}
          {showBlock3 && (
            <div>
              <StructuralChat
                onChatValuesChange={handleChatValuesChange}
                chatType={chatType}
              />
            </div>
          )}
          {showBlock4 && (
            <div className='showBlock4'>
              <label>Assistant Prompt</label>
              <ChatAssistant
                onAssistantValuesChange={handleAssistantValuesChange}
                chatType={chatType}
              />
            </div>
          )}
          
        </div>
        <div className='row2'>
          {!regenerateVisible && (
            <button type='submit' disabled={!textString.trim()}>
              Submit
            </button>
          )}
          {regenerateVisible && (
            <button
              type='button'
              className='regenerate-button'
              onClick={handleRegenerate}
            >
              Regenerate
            </button>
          )}
        </div>
      </form>

      {inputText !== '' && (
        <div className='showBlock1-Input'>
          <h2>Eingabe:</h2>
          <textarea  type='text' id='inputText' value={inputText}  ref={promptRef} onChange={handleInputChange} />
          <span className='spanButton' onClick={handleCopyPrompt}>
          <FaRegCopy />
        </span>
        {isPopupVisible1 && <div className='popup'>Erfolgreich kopiert</div>}
        </div>
      )}
      <div className='output'>
        <h2>Ergebnis:</h2>
        <textarea id='outputText' 
        value={ergebnisWert}   
        placeholder={placeholderOutputText} 
        ref={promptOutputRef} 
        onChange={handleOutputChange} 
        disabled={outputDisabled}/>
      </div>
    </div>
  )
}

export default ChatComponent
