import React, { useState } from "react";
import PasswordChangeInput from "./PasswordChangeInput";
import { callBackend, useSharedData } from "../SharedDataContext";

const PasswordChange = ({ setNewPassword, onClose }) => {
	const { loggedInUser } = useSharedData();
	const [state, setState] = useState({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
		message: "",
	});

	const handleInputChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const { currentPassword, newPassword, confirmPassword } = state;

		callBackend({
			action: "verify_password",
			payload: {
				strPassword: currentPassword,
				pkUserID: loggedInUser.pkUserID,
			},
		})
			.then((rst) => {
				if (rst.serverAction !== "login_success") {
					setState({
						...state,
						message: "Aktuelles Passwort überprüfen!",
					});
				} else if (newPassword !== confirmPassword) {
					setState({
						...state,
						message: "Neue Passwörter stimmen nicht überein",
					});
				} else if (
					!/^(?=(.*[0-9]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*([^\s\w]|_)){1,}).{8,}$/.test(
						newPassword
					)
				) {
					setState({
						...state,
						message:
							"Das Passwort muss mindestens 8 Zeichen lang sein und jeweils mindestens einen Großbuchstaben, einen Kleinbuchstaben , eine Zahl und ein Sonderzeichen enthalten!",
					});
				} else {
					// Add your password change logic here

					callBackend({
						action: "update_password",
						payload: {
							pkUserID: loggedInUser.pkUserID,
							strPassword: currentPassword,
							strNewPassword: newPassword,
						},
					})
						.then((rst) => {
							if (
								rst.serverAction === "database_update_success"
							) {
								setState({
									...state,
									message:
										"Das Passwort wurde erfolgreich geändert",
								});
							}
						})
						.catch((err) => console.log(err));
				}
			})
			.catch((err) => console.log(err));
	};

	return (
		<div>
			<h2>Passwort ändern</h2>
			<form onSubmit={handleSubmit}>
				<input
					type="hidden"
					autoComplete="username"
					value={loggedInUser.strUserName}
				/>
				<PasswordChangeInput
					label="Aktuelles Passwort"
					name="currentPassword"
					placeholder="Aktuelles Passwort"
					value={state.currentPassword}
					onChange={handleInputChange}
				/>
				<PasswordChangeInput
					label="Neues Passwort"
					name="newPassword"
					placeholder="Neues Passwort"
					value={state.newPassword}
					onChange={handleInputChange}
				/>
				<PasswordChangeInput
					label="Bestätige neues Passwort"
					name="confirmPassword"
					placeholder="Bestätige neues Passwort"
					value={state.confirmPassword}
					onChange={handleInputChange}
				/>
				<div>
					<button type="button" onClick={onClose}>
						<p>Schließen</p>
					</button>
					<button type="submit">
						<p>Passwort festlegen</p>
					</button>
				</div>
			</form>
			{state.message && <p>{state.message}</p>}
		</div>
	);
};

export default PasswordChange;
