import React, { useState } from "react";
import { callBackend, formatValues, useSharedData } from "../SharedDataContext";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const NeueBenutzer = () => {
	const {
		departments,
		setDepartments,
		users,
		setUsers,
		fkCompanyID,
		firmusers,
		setFirmUsers,
	} = useSharedData();

	const [formData, setFormData] = useState({
		strFirstName: "",
		strSurName: "",
		strUserName: "",
		strEmail: "",
		strPassword: "",
		intMainDepartment: "",
		fkCompanyID: fkCompanyID,
	});

	const handleInputChange = (event) => {
		const { id, value } = event.target;
		setFormData({ ...formData, [id]: value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const {
			strFirstName,
			strSurName,
			strUserName,
			strEmail,
			strPassword,
			intMainDepartment,
			fkCompanyID,
		} = formData;

		if (
			!strFirstName ||
			!strSurName ||
			!strUserName ||
			!strEmail ||
			!strPassword ||
			!intMainDepartment
		) {
			swal("Bitte füllen Sie alle Felder aus.");
			return;
		}
		if (users.find((user) => user.strUserName === strUserName)) {
			swal("Login bereits vergeben!");
			return;
		}
		if (users.find((user) => user.strEmail === strEmail)) {
			swal("Email bereits vergeben!");
			return;
		}
		if (!/^[\w\.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(strEmail)) {
			swal("Bitte Email überprüfen!");
			return;
		}
		if (
			!/^(?=(.*[0-9]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*([^\s\w]|_)){1,}).{8,}$/.test(
				strPassword
			)
		) {
			swal(
				"Das Passwort muss mindestens 8 Zeichen lang sein und jeweils mindestens einen Großbuchstaben, einen Kleinbuchstaben , eine Zahl und ein Sonderzeichen enthalten!"
			);
			return;
		}

		// Create a new user object
		callBackend({
			action: "register_user",
			payload: formData,
		})
			.then((rst) => {
				if (rst.serverAction === "registration_success") {
					const arrNewUser = formatValues([rst.userData]);
					setUsers([...users, ...arrNewUser]);
					setFirmUsers([...firmusers, ...arrNewUser]);
					setDepartments([
						...departments,
						{
							fkDepartmentID: Number(intMainDepartment),
							fkUserID: arrNewUser[0].pkUserID,
						},
					]);
				}

				// Reset the form fields
				setFormData({
					strFirstName: "",
					strSurName: "",
					strUserName: "",
					strEmail: "",
					strPassword: "",
					intMainDepartment: "",
					fkCompanyID: fkCompanyID,
				});
			})
			.catch((err) => console.log(err));
	};

	return (
		<div className="neueBenuzerDiv">
			<h2> Neue Benutzer </h2>
			<form onSubmit={handleSubmit}>
				<div className="neueBenutzerFormDiv">
					<hr style={{ marginTop: "0px" }} />
					<div>
						<label htmlFor="strFirstName">Vorname:</label>
						<input
							type="text"
							placeholder="Vorname"
							id="strFirstName"
							value={formData.strFirstName}
							onChange={handleInputChange}
							required
						/>
					</div>
					<div>
						<label htmlFor="strSurName">Nachname:</label>
						<input
							type="text"
							placeholder="Nachname"
							id="strSurName"
							value={formData.strSurName}
							onChange={handleInputChange}
							required
						/>
					</div>
					<div>
						<label htmlFor="strUserName">BenutzerID:</label>
						<input
							type="text"
							placeholder="BenutzerID"
							id="strUserName"
							name="newUser"
							value={formData.strUserName}
							onChange={handleInputChange}
							autocomplete="off"
							required
						/>
					</div>
					<div>
						<label htmlFor="Email">E-mail:</label>
						<input
							type="email"
							placeholder="E-mail"
							id="strEmail"
							value={formData.strEmail}
							onChange={handleInputChange}
							required
						/>
					</div>
					<div>
						<label htmlFor="strPassword">Passwort:</label>
						<input
							type="text"
							placeholder="Passwort"
							id="strPassword"
							name="newPassword"
							value={formData.strPasswort}
							onChange={handleInputChange}
							autocomplete="off"
							required
						/>
					</div>

					<div>
						<label htmlFor="intMainDepartment">Abteilung:</label>
						<select
							id="intMainDepartment"
							value={formData.intMainDepartment}
							onChange={handleInputChange}
							required
						>
							<option value="">Bereich Auswählen</option>
							{departments
								.filter(
									(division) =>
										division.fkCompanyID === fkCompanyID
								)
								.map((division) => (
									<option
										key={division.pkDepartmentID}
										value={Number(division.pkDepartmentID)}
									>
										{division.strDepartment}
									</option>
								))}
						</select>
					</div>
					<hr style={{ marginBottom: "0px" }} />
					<div>
						<button type="submit">Registrieren</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default NeueBenutzer;
