import React, { useState } from "react";

const UnterkategorieForm = (props) => {
	const [neueUnterkategorie, setNeueUnterkategorie] = useState("");
	const handleInputChange = (event) => {
		setNeueUnterkategorie(event.target.value);
	};

	const submitHandler = (event) => {
		event.preventDefault(); // Prevent the default form submission behavior
		// Access the 'neueKategorie' value from the state variable and use it as needed
		const kategorieID = props.kategorieList.find((item) =>
				item.strPromptCategoryName === props.ausgewaehlteKategorie
		)?.pkDataPromptCategoryID || 0;
		const unterkategorieExists = props.unterkategorieList.filter((obj)=>
			obj.fkDataPromptCategoryID === kategorieID
		).map((obj) => obj.strPromptSubCategoryName.toLowerCase())
			.includes(neueUnterkategorie.toLowerCase());

		if (unterkategorieExists) {
			window.alert(
				`Die Unterkategorie '${neueUnterkategorie}' existiert schon!`
			);
		} else {
			props.onSaveData(neueUnterkategorie);
		}
		// You can save it in another variable or perform any other actions here
	};
	return (
		<form className="UnterkategorieForm">
			<div className="inputFeld">
				<label>Unterkategorie Name </label>
				<input type="text" onChange={handleInputChange} />
			</div>

			<div className="buttonsFeld">
				<button
					className="abbrechenBtn"
					type="button"
					onClick={props.onCancel}
				>
					Abbrechen
				</button>
				<button
					className="submitBtn"
					type="submit"
					onClick={submitHandler}
				>
					hinzufügen
				</button>
			</div>
		</form>
	);
};

export default UnterkategorieForm;
