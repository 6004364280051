import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Login from './pages/Login'

import Error from './pages/Error'
import Erfassung from './pages/Erfassung'
import Dashboard from './pages/Dashboard'
import Einstellung from './pages/Einstellung'
import Ergebnis from './pages/Ergebnis'

import { SharedDataProvider } from './SharedDataContext'

function App() {
  return (
    <div>
      <BrowserRouter>
        <SharedDataProvider>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='erfassung' element={<Erfassung />} />
            <Route path='ergebnis/*' element={<Ergebnis />} />
            {/* <Route path='details' element={<PromptDetails />} /> */}
            <Route path='einstellung' element={<Einstellung />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </SharedDataProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
