import { useEffect, useState } from "react";

import {
	SwipeableList,
	LeadingActions,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
} from "react-swipeable-list";
import BookmarkOn from "../images/bookmark_on.svg";
import BookmarkOff from "../images/bookmark_off.svg";
import Next from "../images/next.svg";

import { RiDeleteBin6Line } from "react-icons/ri";
import { BsBookmarkStar } from "react-icons/bs";
import { GoBookmarkSlash } from "react-icons/go";

import { Link } from "react-router-dom";

import { callBackend, useSharedData } from "../SharedDataContext";

//
// items,
// setItems,
// kategorieList,
// unterkategorieList,

// {
//   key,
//   id,
//   input,
//   output,
//   date,
//   comment,
//   type,
//   fkUserID,
//   eigenschaftIDs,
//   kategorieList,
//   unterkategorieList,
//   tagIDs,
//   tags,
//   setTags,
// }
// props.comment,
// props.type,
// props.fkUserID,
// props.eigenschaftIDs,
// props.kategorieList,
// props.unterkategorieList,

const PromptItem = (props) => {
	const id = props.id;
	const input = props.input;
	const output = props.output;
	const date = props.date;
	const promptname = props.name;
	const loggedInUser = props.loggedInUser;

	const {
		prompts,
		setPrompts,
		users,
		tags,
		setTags,
		firmusers,
		departments,
		markiertePrompts,
		setMarkiertePrompts,
	} = useSharedData();

	const [autor, setAutor] = useState("");
	const [userBereich, setUserBereich] = useState("");

	const [changeable, setChangeable] = useState(false);

	const [marked, setMarked] = useState(0);

	let thisKategorieID = (eigenschaftID) => {
		return props.unterkategorieList.find(
			(item) => item.pkDataPromptSubCategoryID === eigenschaftID
		).pkDataPromptCategoryID;
	};
	let thisKategorie = (eigenschaftID) => {
		return props.kategorieList.find(
			(item) =>
				item.pkDataPromptCategoryID === thisKategorieID(eigenschaftID)
		);
	};
	//Swipe action

	// Ob Prompt schon gepeichert in markierte Prompts

	// let changeable = () => {
	//   if (loggedInUser === null) {
	//     return false
	//   } else {
	//     if (
	//       prompts.find((item) => item.pkPromptID === id).fkUserID ===
	//       loggedInUser.pkUserID
	//     ) {
	//       return true
	//     } else return false
	//   }
	// }
	// const isChangeable = changeable()

	useEffect(() => {
		if (loggedInUser === null) {
			//setChangeable(false)
			// return false
		} else {
			if (
				prompts.find((item) => item.pkPromptID === id)?.fkUserID ===
					loggedInUser.pkUserID ||
				loggedInUser.fkDataRoleID === 0
			) {
				setChangeable(true);
			}
		}

		const autorData = users.filter((u) => u.pkUserID === props.fkUserID);

		if (autorData && autorData.length > 0) {
			const autor_vorname = autorData[0].strFirstName;
			const autor_name = autorData[0].strSurName;

			setAutor(autor_vorname + " " + autor_name);
		}
		if (users && users.length > 0) {
			const userBereichID = users.find(
				(item) => item.pkUserID === props.fkUserID
			).intMainDepartment;

			let userBereich = "";
			if (userBereichID && userBereichID > 0) {
				const filteredData = departments.filter(
					(item) =>
						item.pkDepartmentID === Number(userBereichID) &&
						item.fkCompanyID === Number(loggedInUser.fkCompanyID)
				);
				// Get the 'Name' variable if there's a matching item
				const name =
					filteredData.length > 0
						? filteredData[0].strDepartment
						: "Not Found";

				const bereichData = departments.filter(
					(u) =>
						u.pkDepartmentID === userBereichID &&
						u.fkCompanyID === loggedInUser.fkCompanyID
				);
				if (bereichData && bereichData.length > 0) {
					userBereich = bereichData[0].strDepartment;

					setUserBereich(name);
				}
			}
		}
	}, []);
	useEffect(() => {
		const selectedPrompt = markiertePrompts.filter(
			(item) =>
				item?.fkUserID === loggedInUser.pkUserID &&
				item.fkPromptID === id
		);
		if (!selectedPrompt || selectedPrompt.length === 0) {
			setMarked(0);
		} else {
			setMarked(1);
		}
	}, []);
	//prüfung ob loggedInUser nicht leer,
	// const isChangeable = changeable() // Call the function to get the result (true or false)
	const onMarkItem = () => {
		if (marked === 1) {
			setMarked(0);
			setMarkiertePrompts(
				markiertePrompts.filter((item) => item.fkPromptID !== id)
			);
			callBackend({
				action: "delete_bookmark",
				payload: { fkPromptID: id, fkUserID: loggedInUser.pkUserID },
			});
		}
		if (marked === 0) {
			setMarked(1);
			const newMarkiertPrompt = {
				fkPromptID: id,
				fkUserID: loggedInUser.pkUserID,
				Datum: new Date(),
			};
			markiertePrompts.push(newMarkiertPrompt);
			setMarkiertePrompts(markiertePrompts);
			callBackend({
				action: "save_bookmark",
				payload: { fkPromptID: id, fkUserID: loggedInUser.pkUserID },
			});
		}
	};
	const onDeleteItem = () => {
		if (changeable === true) {
			if (marked === 1) {
				setMarkiertePrompts(
					markiertePrompts.filter((item) => item.fkPromptID !== id)
				);
			}
			callBackend({
				action: "delete_prompt",
				payload: { pkPromptID: id },
			});
			callBackend({
				action: "delete_bookmark",
				payload: { fkPromptID: id },
			});
			callBackend({
				action: "delete_prompt_category",
				payload: { fkPromptID: id },
			});
			callBackend({
				action: "delete_prompt_tag",
				payload: { fkPromptID: id },
			});
			const filtereditems = prompts.filter(
				(item) => item.pkPromptID !== id
			);
			setPrompts(filtereditems);
		}
	};
	const leadingActions = () => (
		<LeadingActions>
			<SwipeAction
				onClick={() => {
					onMarkItem();
				}}
			>
				{marked === 0 ? (
					<span>
						Markieren <BsBookmarkStar />
					</span>
				) : marked === 1 ? (
					<span>
						Nicht markieren <GoBookmarkSlash />
					</span>
				) : (
					"X"
				)}
			</SwipeAction>
		</LeadingActions>
	);
	const trailingActions = () => (
		<TrailingActions>
			<SwipeAction onClick={() => onDeleteItem()}>
				{changeable === true ? (
					<span>
						<RiDeleteBin6Line />
						Löschen
					</span>
				) : (
					""
				)}
			</SwipeAction>
		</TrailingActions>
	);
	return (
		// PromptItem als swipeable Item
		<div className="PromptSwipeable">
			<SwipeableList fullSwipe={true}>
				<SwipeableListItem
					leadingActions={leadingActions()}
					trailingActions={trailingActions()}
				>
					<div key={id} className="PromptSwipeable">
						<div className="row-1">{promptname}</div>
						{marked === 1 ? (
							<img
								className="bookmark"
								onClick={onMarkItem}
								src={BookmarkOn}
							/>
						) : (
							<img
								className="bookmark"
								onClick={onMarkItem}
								src={BookmarkOff}
							/>
						)}
						<div className="row-2">
							<span className="text-title">Prompt: </span>
							<div className="text-container text-container-2rows">
								{input}
							</div>
						</div>
						<div className="row-3">
							<span className="text-title">Ergebnis:</span>
							<div className="text-container text-container-3rows">
								{output}
							</div>
						</div>
						<div className="row-4">
							<span> {autor}</span>
							<span> {userBereich}</span>
							<span>{date}</span>

							<Link to={`/ergebnis/${id}`}>
								<span>
									Volltext zeigen{" "}
									<img className="next" src={Next} />
								</span>
							</Link>
						</div>
					</div>
				</SwipeableListItem>
			</SwipeableList>
		</div>
	);
};

export default PromptItem;
