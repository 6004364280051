import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useSharedData } from "../SharedDataContext";

const DashboardChart = (props) => {
	const { filteredPrompts, users } =
		useSharedData();
	const authorCounts = {};
	const newprompts = [];
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: "Number of Articles per Author",
				data: [],
				backgroundColor: "rgba(75, 192, 192, 0.6)",
			},
		],
	});

	const getName = (userID) => {
		const user = users.find((div) => div.pkUserID === Number(userID));
		return user ? user.strSurName + " " + user.strFirstName : "Unbekannt";
	};

	useEffect(() => {
		if (newprompts.length === 0) {
			for (const elm of filteredPrompts) {
				let newItem = {};
				newItem["pkPromptID"] = elm.pkPromptID;
				let userid = elm.fkUserID;
				let name = getName(userid);
				newItem["Name"] = name;
				newprompts.push(newItem);
			}

			newprompts.forEach((article) => {
				if (authorCounts[article.Name]) {
					authorCounts[article.Name]++;
				} else {
					authorCounts[article.Name] = 1;
				}
			});

			const labels = Object.keys(authorCounts);
			const dataCounts = Object.values(authorCounts);

			setChartData({
				labels,
				datasets: [
					{
						label: "Prompts Anzahl pro Benutzer",
						data: dataCounts,
						backgroundColor: "rgba(75, 192, 192, 0.6)",
					},
				],
			});
		}
	}, []);

	return (
		<div className="chart1">
			<h1>Dashboard Mitarbeiter</h1>
			<Bar data={chartData} options={{ maintainAspectRatio: false }} />
		</div>
	);
};

export default DashboardChart;
