import React, { useState, useEffect } from "react";
import PropertiesInput from "./PropertiesInput";
import { useSharedData } from "../SharedDataContext";

const StructuralChat = ({ onChatValuesChange, chatType }) => {
	const [formData, setFormData] = useState({
		role: "",
		task: "",
		thema: "",
		stil: "",
		emojis: "",
		länge: {
			min: "",
			max: "",
		},
		zielgruppe: "",
		callToAction: "",
		form: "",
		sprache: "",
		format: "",
		platform: "",
	});

	const handleChange = (event) => {
		const { name, value } = event.target;

		if (name.includes(".")) {
			// Handle nested fields
			const [parentName, childName] = name.split(".");
			setFormData((prevState) => ({
				...prevState,
				[parentName]: {
					...prevState[parentName],
					[childName]: value,
				},
			}));
		} else {
			// Handle direct fields
			setFormData((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const [roleValue, setRoleValue] = useState("");
	const [selectedRoleOption, setSelectedRoleOption] =
		useState("-Bitte auswählen-");
	const [taskValue, setTaskValue] = useState("");
	const [selectedTaskOption, setSelectedTaskOption] =
		useState("-Bitte auswählen-");

	const [formatValue, setFormatValue] = useState("");
	const [selectedFormatOption, setSelectedFormatOption] =
		useState("-Bitte auswählen-");
	const [platformValue, setPlatformValue] = useState("");
	const [selectedPlatformOption, setSelectedPlatformOption] =
		useState("-Bitte auswählen-");

	const {
		promptData,
		setPromptData,
		languages,
		outputs,
		platforms,
		roles,
		styles,
		tasks,
	} = useSharedData();

	/* ToDo: Werte zuordnen:
		intPromptKind: 0,
		txtSubject: null,

    thema
    stil
    form
    */

	const handleGenericInput = (e) => {
		e.preventDefault();
		if (/^(pk|fk|int)/.test(e.target.id)) {
			const insertValue = Number(e.target.value);
			setPromptData({
				strID: e.target.id,
				value: insertValue && insertValue > 0 ? insertValue : 0,
			});
		} else if (/^(txt|dat|str)/.test(e.target.id)) {
			setPromptData({ strID: e.target.id, value: e.target.value });
		}
		const updatedFormData = structuredClone(formData);
		switch (e.target.id) {
			case "fkDataPromptRoleID":
				const txtPromptRole =
					roles.find(
						(obj) =>
							obj.pkDataPromptRoleID === Number(e.target.value)
					)?.strPromptRoleName || "";
				setSelectedRoleOption(txtPromptRole);
				updatedFormData.role = `${txtPromptRole}${
					roleValue ? " " + roleValue : ""
				}`;
				break;
			case "roleValue":
				setRoleValue(e.target.value);
				updatedFormData.role = `${
					selectedRoleOption ? selectedRoleOption + " " : ""
				}${e.target.value}`;
				break;
			case "fkDataPromptOutputID":
				const txtPromptFormat =
					outputs.find(
						(obj) =>
							obj.pkDataPromptOutputID === Number(e.target.value)
					)?.strPromptOutputName || "";
				setSelectedFormatOption(txtPromptFormat);
				updatedFormData.format = `${txtPromptFormat}${
					formatValue ? " " + formatValue : ""
				}`;
				break;
			case "formatValue":
				setFormatValue(e.target.value);
				updatedFormData.format = `${
					selectedFormatOption ? selectedFormatOption + " " : ""
				}${e.target.value}`;
				break;
			case "fkDataPromptProvideID":
				const txtPromptTask =
					tasks.find(
						(obj) =>
							obj.pkDataPromptProvideID === Number(e.target.value)
					)?.strPromptProvideName || "";
				setSelectedTaskOption(txtPromptTask);
				updatedFormData.task = `${txtPromptTask}${
					taskValue ? " " + taskValue : ""
				}`;
				break;
			case "txtSubject":
				setTaskValue(e.target.value);
				updatedFormData.task = `${
					selectedTaskOption ? selectedTaskOption + " " : ""
				}${e.target.value}`;
				break;
			case "fkDataPromptPlatformID":
				const txtPromptPlatform =
					platforms.find(
						(obj) =>
							obj.pkDataPromptPlatformID ===
							Number(e.target.value)
					)?.strPromptPlatformName || "";
				setSelectedPlatformOption(txtPromptPlatform);
				updatedFormData.platform = `${txtPromptPlatform}${
					platformValue ? " " + platformValue : ""
				}`;
				break;
			case "platformValue":
				setPlatformValue(e.target.value);
				updatedFormData.platform = `${
					selectedPlatformOption ? selectedPlatformOption + " " : ""
				}${e.target.value}`;
				break;
			case "fkDataLanguageID":
				updatedFormData.sprache =
					languages.find(
						(obj) => obj.pkDataLanguageID === Number(e.target.value)
					)?.strLanguage || "";
				break;
			case "txtAudience":
				updatedFormData.zielgruppe = e.target.value;
				break;
			case "intMaxLength":
				const valMaxLength = Number(e.target.value);
				updatedFormData.länge.max =
					valMaxLength && valMaxLength > 0 ? valMaxLength : 0;
				break;
			case "intMinLength":
				const valMinLength = Number(e.target.value);
				updatedFormData.länge.min =
					valMinLength && valMinLength > 0 ? valMinLength : 0;
				break;
			case "txtCallToAction":
				updatedFormData.callToAction = e.target.value;
				break;
			case "intEmojis":
				const arrEmojis = ["", "wenig", "viel"];
				updatedFormData.emojis = arrEmojis[e.target.value];
				break;
			case "txtForm":
				updatedFormData.form = e.target.value;
				break;
			// case "":
			//   break;
			default:
				break;
		}
		setFormData(updatedFormData);
	};

	useEffect(() => {
		onChatValuesChange(formData);
	}, [formData]);

	return (
		<div className="structuralChat">
			{roles && (
				<PropertiesInput
					label="handeln als [ROLLE]:"
					options={roles.map((obj) => ({
						id: obj.pkDataPromptRoleID,
						option: obj.strPromptRoleName,
					}))}
					optionID={"fkDataPromptRoleID"}
					textID={"roleValue"}
					inputValue={roleValue}
					selectedOption={promptData.fkDataPromptRoleID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					chatType={chatType}
				/>
			)}

			{tasks && (
				<PropertiesInput
					label="erstellen [AUFGABE]:"
					options={tasks.map((obj) => ({
						id: obj.pkDataPromptProvideID,
						option: obj.strPromptProvideName,
					}))}
					optionID={"fkDataPromptProvideID"}
					textID={"txtSubject"}
					inputValue={taskValue}
					selectedOption={promptData.fkDataPromptTaskID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					chatType={chatType}
				/>
			)}
			<div>
				<label>Thema:</label>
				<input
					type="text"
					name="thema"
					placeholder="Thema"
					value={formData.thema || ""}
					onChange={handleChange}
				/>
			</div>
			{
				/* The expression `chatType==='option2'  & ` is checking if the value of `chatType` is equal to
`'option2'`. If it is, the expression will evaluate to `true`, otherwise it will evaluate to
`false`. */
				chatType === "option3" && (
					<form>
						<div>
							<label>Stil:</label>
							<select
								name="stil"
								value={formData.stil}
								onChange={handleChange}
							>
								<option value="">-Bitte auswählen -</option>
								{styles.map((obj, idx) => (
									<option
										value={obj.pkDataPromptStyleID}
										key={`style_${idx}`}
									>
										{obj.strPromptStyleName}
									</option>
								))}
							</select>
						</div>
						<div>
							<label>Emojis:</label>
							<select
								name="emojis"
								id="intEmojis"
								value={promptData.intEmojis}
								onChange={handleGenericInput}
							>
								<option value="0">-Bitte auswählen -</option>
								{/* <option value='1'>keine</option> */}
								<option value="1">wenig</option>
								<option value="2">viel</option>
							</select>
						</div>
						<div>
							<label>Länge:</label>
							<input
								type="text"
								id="intMinLength"
								name="länge.min"
								placeholder="Min"
								value={promptData.intMinLength || ""}
								onChange={handleGenericInput}
							/>
							<input
								type="text"
								id="intMaxLength"
								name="länge.max"
								placeholder="Max"
								value={promptData.intMaxLength || ""}
								onChange={handleGenericInput}
							/>
						</div>
						<div>
							<label>Zielgruppe:</label>
							<input
								type="text"
								id="txtAudience"
								name="zielgruppe"
								placeholder="Zielgruppe"
								value={promptData.txtAudience || ""}
								onChange={handleGenericInput}
							/>
						</div>
						<div>
							<label>Call to Action:</label>
							<input
								type="text"
								id="txtCallToAction"
								name="callToAction"
								placeholder="Call to Action"
								value={promptData.txtCallToAction || ""}
								onChange={handleGenericInput}
							/>
						</div>
						<div>
							<label>Form:</label>
							<input
								type="text"
								id="txtForm"
								name="form"
								placeholder="Beschreiben"
								value={promptData.txtForm || ""}
								onChange={handleGenericInput}
							/>
						</div>
						<div>
							<label>Sprache:</label>
							<select
								name="sprache"
								id="fkDataLanguageID"
								value={promptData.fkDataLanguageID}
								onChange={handleGenericInput}
							>
								<option value={0}>-Bitte auswählen -</option>
								{languages.map((obj, idx) => (
									<option
										value={obj.pkDataLanguageID}
										key={`language_${idx}`}
									>
										{obj.strLanguage}
									</option>
								))}
							</select>
						</div>
					</form>
				)
			}
			{outputs && chatType === "option3" && (
				<PropertiesInput
					label="folgendes [FORMAT]:"
					options={outputs.map((obj) => ({
						id: obj.pkDataPromptOutputID,
						option: obj.strPromptOutputName,
					}))}
					inputValue={formatValue}
					optionID={"fkDataPromptOutputID"}
					textID={"formatValue"}
					selectedOption={promptData.fkDataPromptOutputID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					chatType={chatType}
				/>
			)}

			{platforms && chatType === "option3" && (
				<PropertiesInput
					label="Platform:"
					options={platforms.map((obj) => ({
						id: obj.pkDataPromptPlatformID,
						option: obj.strPromptPlatformName,
					}))}
					optionID={"fkDataPromptPlatformID"}
					textID={"platformValue"}
					inputValue={platformValue}
					selectedOption={promptData.fkDataPromptPlatformID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					chatType={chatType}
				/>
			)}
		</div>
	);
};

export default StructuralChat;
