import { useState } from 'react'
import { TiDelete } from 'react-icons/ti'
import { callBackend, useSharedData } from "../../SharedDataContext";

const EigenschaftItem = ({
  id,
  name,
  isChangeable,
}) => {
  const [isVisible, setIsVisible] = useState(true)
	const { promptCategories, setPromptCategories } = useSharedData();

  const handleDeleteClick = () => {
    setIsVisible(false)
    setPromptCategories(promptCategories.filter((obj)=>obj.pkPromptCategoryID !== id))
    callBackend({ action: "delete_prompt_category", payload: {pkPromptCategoryID: id} });
  }

  return (
    <div className='EigenschaftItem'>
      {isVisible && (
        <div>
          <button className='Eigenschaft-button'>
            {name}
            {isChangeable === true ? (
              <TiDelete className='deleteButton' onClick={handleDeleteClick} />
            ) : (
              ''
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export default EigenschaftItem
