import React, { useEffect, useRef } from "react";

import EigenschaftItem from "./Kategorien_Unterkategorien_Eigenschaften/EigenschaftItem";
import TagItem from "./Tags/TagItem";

import { useState } from "react";
import { useSharedData } from "../SharedDataContext";

import KategorienComponent from "./Kategorien_Unterkategorien_Eigenschaften/KategorienComponent";

import BookmarkOn from "../images/bookmark_on.svg";
import BookmarkOff from "../images/bookmark_off.svg";

import { useLocation } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { FcBookmark } from "react-icons/fc";
import { useNavigate } from "react-router";

import { FaRegCopy } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

import swal from "sweetalert";

//
// items,
// setItems,
// kategorieList,
// unterkategorieList,

const PromptItemDetails = ({ loggedInUser }) => {
	const location = useLocation();

	const navigate = useNavigate();

	const {
		prompts,
		setPrompts,
		tags,
		setTags,
		users,
		departments,
		kategorieList,
		unterkategorieList,
		markiertePrompts,
		setMarkiertePrompts,
		promptCategories,
		setPromptCategories,
		promptTags,
		setPromptTags,
		callBackend,
	} = useSharedData();

	const [eigenschaftenWert, setEigenschaftenWert] = useState([]);
	const promptId = Number(location.pathname.split("/")[2]);
	const prompt = prompts.find((item) => item.pkPromptID === promptId);
	if (typeof prompt !== "object" || typeof loggedInUser === "undefined") {
		window.location.href = "/";
	}
	const eigenschaftIDs = promptCategories.filter(
		(entry) => entry.fkPromptID == promptId
	);
	const [marked, setMarked] = useState(0);

	const autor_vorname = users.find(
		(item) => item.pkUserID === prompt?.fkUserID
	).strFirstName;
	const autor_name = users.find(
		(item) => item.pkUserID === prompt?.fkUserID
	).strSurName;
	const autor = autor_vorname + autor_name;

	const userBereichID = users.find(
		(item) => item.pkUserID === prompt?.fkUserID
	).intMainDepartment;
	const userBereich = departments.find(
		(item) => item.pkDepartmentID === userBereichID
	).strDepartment;

	useEffect(() => {
		const selectedPrompt = markiertePrompts.filter(
			(item) =>
				item.fkUserID === loggedInUser?.pkUserID &&
				item.fkPromptID === promptId
		);
		if (!selectedPrompt || selectedPrompt.length === 0) {
			setMarked(0);
		} else {
			setMarked(1);
		}

		const filtered = eigenschaftIDs.map((element) => ({
			id: element.pkPromptCategoryID,
			eigenschaftName: `${
				kategorieList.find(
					(kategorie) =>
						kategorie.pkDataPromptCategoryID ===
						element.fkDataPromptCategoryID
				)?.strPromptCategoryName
			}: ${
				unterkategorieList.find(
					(unterKategorie) =>
						unterKategorie.pkDataPromptSubCategoryID ===
						element.fkDataPromptSubCategoryID
				)?.strPromptSubCategoryName
			}`,
		}));
		setEigenschaftenWert(filtered);
	}, []);

	let changeable = () => {
		if (loggedInUser === undefined || loggedInUser === null) {
			return false;
		} else {
			if (
				prompts.find((item) => item.pkPromptID === promptId)
					?.fkUserID === loggedInUser.pkUserID
			) {
				return true;
			} else return false;
		}
	};
	const isChangeable = changeable();

	let thisTagName = (TagID) => {
		return tags.find((item) => item.pkDataPromptTagID === TagID)
			?.strDataPromptTag;
	};

	const handleMarkieren = () => {
		setMarked(1);
		const newMarkiertPrompt = {
			fkPromptID: promptId,
			fkUserID: loggedInUser.pkUserID,
			Datum: new Date(),
		};

		markiertePrompts.push(newMarkiertPrompt);
		setMarkiertePrompts(markiertePrompts);
		callBackend({
			action: "save_bookmark",
			payload: { fkPromptID: promptId, fkUserID: loggedInUser.pkUserID },
		});
	};
	const handleNichtMarkieren = () => {
		setMarked(0);
		let neueListe = markiertePrompts.filter(
			(item) => item.fkPromptID !== promptId
		);
		setMarkiertePrompts(
			neueListe.map((item, index) => {
				item.lfdMarkedID = index + 1;
				return item;
			})
		);
		callBackend({
			action: "delete_bookmark",
			payload: { fkPromptID: promptId, fkUserID: loggedInUser.pkUserID },
		});
	};

	const handleLoeschen = () => {
		if (marked === 1) {
			setMarkiertePrompts(
				markiertePrompts.filter((item) => item.fkPromptID !== promptId)
			);
			setPrompts(prompts.filter((item) => item.pkPromptID !== promptId));
		} else {
			setPrompts(prompts.filter((item) => item.pkPromptID !== promptId));
		}
		callBackend({
			action: "delete_prompt",
			payload: { pkPromptID: promptId },
		});
		callBackend({
			action: "delete_bookmark",
			payload: { fkPromptID: promptId },
		});
		callBackend({
			action: "delete_prompt_category",
			payload: { fkPromptID: promptId },
		});
		callBackend({
			action: "delete_prompt_tag",
			payload: { fkPromptID: promptId },
		});
		navigate("/ergebnis");
	};

	const handleZurueck = () => {
		navigate("/ergebnis");
	};

	const promptRef = useRef(null);
	const ergebnisRef = useRef(null);
	const [isPopupVisible1, setPopupVisible1] = useState(false);
	const [isPopupVisible2, setPopupVisible2] = useState(false);

	const handleCopyPrompt = () => {
		// const textToCopy = promptRef.current.value
		// navigator.clipboard.writeText(textToCopy)

		const range = document.createRange();
		range.selectNode(promptRef.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			document.execCommand("copy");
			// You can provide feedback here, e.g., show a success message
			// Show the "kopiert erfolgreich" popup for a brief moment
			setPopupVisible1(true);
			setTimeout(() => {
				setPopupVisible1(false);
			}, 2000); // Adjust the timeout as needed (2 seconds in this example)
		} catch (error) {
			// Handle any errors that may occur during copying
			console.error("Copy failed:", error);
			// You can also provide feedback in case of an error
		} finally {
			window.getSelection().removeAllRanges();
		}
	};

	const handleCopyErgebnis = () => {
		// const textToCopy = ergebnisRef.current.value
		// navigator.clipboard.writeText(textToCopy)

		const range = document.createRange();
		range.selectNode(ergebnisRef.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			document.execCommand("copy");
			// You can provide feedback here, e.g., show a success message
			// Show the "kopiert erfolgreich" popup for a brief moment
			setPopupVisible2(true);
			setTimeout(() => {
				setPopupVisible2(false);
			}, 2000); // Adjust the timeout as needed (2 seconds in this example)
		} catch (error) {
			// Handle any errors that may occur during copying
			console.error("Copy failed:", error);
			// You can also provide feedback in case of an error
		} finally {
			window.getSelection().removeAllRanges();
		}
	};

	const [isEditing_notiz, setIsEditing_notiz] = useState(false);
	const [editedComment, setEditedComment] = useState(prompt.txtComment);

	const handleNotizBearbeiten = () => {
		setIsEditing_notiz(!isEditing_notiz);
	};

	const handleCommentChange = (event) => {
		setEditedComment(event.target.value);
	};

	const handleSaveComment = () => {
		// Save the edited comment (you can implement this logic)
		setIsEditing_notiz(false);
		callBackend({
			action: "update_prompt",
			payload: {
				pkPromptID: prompt.pkPromptID,
				txtComment: editedComment,
			},
		});
		setPrompts(
			prompts.map((obj) =>
				obj.pkPromptID === prompt.pkPromptID
					? { ...obj, txtComment: editedComment }
					: obj
			)
		);
	};

	const [isEditing_title, setIsEditing_title] = useState(false);
	const [editedTitle, setEditedTitle] = useState(prompt.strPromptName);

	const handleTitleBearbeiten = () => {
		setIsEditing_title(!isEditing_title);
	};

	const handleTitleChange = (event) => {
		setEditedTitle(event.target.value);
	};

	const handleSaveTitle = () => {
		// Save the edited comment (you can implement this logic)
		setIsEditing_title(false);
		callBackend({
			action: "update_prompt",
			payload: {
				pkPromptID: prompt.pkPromptID,
				strPromptName: editedTitle,
			},
		});
		setPrompts(
			prompts.map((obj) =>
				obj.pkPromptID === prompt.pkPromptID
					? { ...obj, strPromptName: editedTitle }
					: obj
			)
		);
	};

	const addTag = () => {
		swal("Geben Sie einen neuen Tag ein", {
			content: {
				element: "input",
				attributes: { id: "input_tags", type: "text" },
			},
		}).then((tagValue) => {
			if (tagValue === null || tagValue.trim() === "") {
				swal("Kein Tag wird hinzugefügt!");
			} else {
				const tagAssigned = promptTags
					.filter((entry) => entry.fkPromptID == promptId)
					.map((entry) => entry.fkDataPromptTagID)
					.some(
						(item) =>
							thisTagName(item).toLowerCase() ===
							tagValue.toLowerCase()
					);
				const tagExists = tags.some(
					//ToDo: Tag auch auf User überprüfen?
					(obj) =>
						obj.strDataPromptTag.toLowerCase() ===
						tagValue.toLowerCase()
				);
				if (tagAssigned) {
					swal(`Das Tag '${tagValue}' existiert schon!`);
				} else if (tagExists) {
					const tagID = tags.find(
						(item) =>
							item.strDataPromptTag.toLowerCase() ===
							tagValue.toLowerCase()
					)?.pkDataPromptTagID;

					callBackend({
						action: "save_prompt_tag",
						payload: {
							fkPromptID: promptId,
							fkDataPromptTagID: tagID,
						},
					}).then(() => {});

					const datNow = new Date();
					const newPromptTag = {
						fkPromptID: promptId,
						fkDataPromptTagID: tagID,
						intStatus: 1,
						datTsControl: `${datNow.getFullYear()}-${
							datNow.getMonth() + 1
						}-${datNow.getDate()}`,
					};
					setPromptTags([...promptTags, newPromptTag]);
				} else {
					callBackend({
						action: "save_tag",
						payload: {
							strDataPromptTag: tagValue,
							fkUserID: loggedInUser.pkUserID,
						},
					})
						.then((rst) => {
							const datNow = new Date();
							const newTag = {
								pkDataPromptTagID: rst.pkDataPromptTagID,
								strDataPromptTag: tagValue,
								fkUserID: loggedInUser.pkUserID,
								Datum: `${datNow.getFullYear()}-${
									datNow.getMonth() + 1
								}-${datNow.getDate()}`,
							};
							setTags([...tags, newTag]);

							callBackend({
								action: "save_prompt_tag",
								payload: {
									fkPromptID: promptId,
									fkDataPromptTagID: rst.pkDataPromptTagID,
								},
							}).then(() => {});

							const newPromptTag = {
								fkPromptID: promptId,
								fkDataPromptTagID: rst.pkDataPromptTagID,
								intStatus: 1,
								datTsControl: `${datNow.getFullYear()}-${
									datNow.getMonth() + 1
								}-${datNow.getDate()}`,
							};
							setPromptTags([...promptTags, newPromptTag]);
						})
						.catch((err) => console.log(err));
				}
			}
		});
		document.getElementById("input_tags").setAttribute("list", "tags");
	};

	const [openAddEigenschaft, setOpenAddEigenschaft] = useState(false);

	const addEigenschschaft = () => {
		setOpenAddEigenschaft(!openAddEigenschaft);
	};

	const [exportedData, setExportedData] = useState(null);

	const handleExport = () => {
		// Collect and format the data you want to export here
		const dataToExport = {
			PromptID: promptId,
			Name: prompt.Name,
			Prompt: prompt.Input,
			Ergenis: prompt.Output,
			Notiz: prompt.Comment,
			Eigenschaften: eigenschaftenWert.map(
				(item) => item.eigenschaftName
			),
			Tags: promptTags
				.filter((entry) => entry.fkPromptID == promptId)
				.map((entry) => entry.fkDataPromptTagID)
				.map((item, index) => thisTagName(item)),
			Autor: autor,
			Abteilung: userBereich,
			Datum: prompt.Date,

			// Add other properties as needed
		};

		// Convert the data to a JSON string
		const dataString = JSON.stringify(dataToExport);

		// Create a Blob containing the JSON data
		const blob = new Blob([dataString], { type: "application/json" });

		// Create a URL for the Blob
		const blobURL = URL.createObjectURL(blob);

		// Set the exported data in the component state
		setExportedData(blobURL);
	};

	return (
		<div className="PromptItemDetails">
			{/* <h2>{prompt.Name}</h2> */}
			<div className="details-title">
				{isEditing_title ? (
					<input onChange={handleTitleChange} value={editedTitle} />
				) : (
					<h2>{editedTitle}</h2>
				)}
				{isChangeable === true ? (
					<span
						className="spanButton"
						onClick={handleTitleBearbeiten}
					>
						{isEditing_title ? (
							<button onClick={handleSaveTitle}>Save</button>
						) : (
							<FiEdit />
						)}
					</span>
				) : (
					""
				)}
			</div>

			<div className="details-eingabe">
				<span className="spanTitel">Prompt: </span>
				<span className="spanContent" ref={promptRef}>
					{prompt.txtImput}
				</span>

				<span className="spanButton" onClick={handleCopyPrompt}>
					<FaRegCopy />
				</span>
				{isPopupVisible1 && (
					<div className="popup">Erfolgreich kopiert</div>
				)}
				{/* <textarea ref={promptRef} type='text' value={prompt.Input} readOnly />

        <button onClick={handleCopyPrompt}>Copy</button> */}
			</div>
			<div className="details-ausgabe">
				<span className="spanTitel">Ergebnis: </span>

				<span className="spanContent" ref={ergebnisRef}>
					{prompt.txtOutput}
				</span>
				<span className="spanButton" onClick={handleCopyErgebnis}>
					<FaRegCopy />
				</span>
				{isPopupVisible2 && (
					<div className="popup">Erfolgreich kopiert</div>
				)}

				{/* <button onClick={handleCopyErgebnis}>Copy</button> */}
			</div>
			<div className="details-comment">
				<span className="spanTitel">Notiz: </span>
				{isEditing_notiz ? (
					<textarea
						value={editedComment}
						onChange={handleCommentChange}
					/>
				) : (
					<span>{editedComment}</span>
				)}
				{isChangeable === true ? (
					<span
						className="spanButton"
						onClick={handleNotizBearbeiten}
					>
						{isEditing_notiz ? (
							<button onClick={handleSaveComment}>Save</button>
						) : (
							<FiEdit />
						)}
					</span>
				) : (
					""
				)}
			</div>
			<div className="details-autor">Autor: {autor}</div>
			<div className="details-bereich">Abteilung: {userBereich}</div>
			<div className="details-datum">Datum: {prompt.datCreate}</div>
			{/* <div className='details-notiz'>
  			<NotizListe notizen={prompt.Comment} /> 
      </div> */}
			<div className="details-mark">
				{marked === 1 ? (
					<img
						className="bookmark"
						onClick={handleNichtMarkieren}
						src={BookmarkOn}
					/>
				) : (
					<img
						className="bookmark"
						onClick={handleMarkieren}
						src={BookmarkOff}
					/>
				)}
			</div>
			<div className="details-eigenschaften">
				<h4>Eigenschaften:</h4>
				<div className="details-eigenschaften-container">
					{eigenschaftenWert.map((item, index) => (
						<EigenschaftItem
							key={`Category_${index}`}
							id={item.id}
							name={item.eigenschaftName}
							isChangeable={isChangeable}
						/>
					))}
					{isChangeable && (
						<AiFillPlusCircle onClick={addEigenschschaft} />
					)}

					{openAddEigenschaft === true ? (
						<KategorienComponent
							promptID={promptId}
							eigenschaftenWert={eigenschaftenWert}
							setEigenschaftenWert={setEigenschaftenWert}
							kategorieList={kategorieList}
							unterkategorieList={unterkategorieList}
						/>
					) : (
						""
					)}
				</div>
			</div>
			<div className="details-tags">
				<h4>Tags:</h4>
				<div className="details-tags-container">
					{promptTags
						.filter((entry) => entry.fkPromptID == promptId)
						.map((entry) => entry.fkDataPromptTagID)
						.map((item, index) => (
							<TagItem
								key={`Tag_${index}`}
								tagID={item}
								promptID={promptId}
								name={thisTagName(item)}
								isChangeable={isChangeable}
							/>
						))}
					{isChangeable && <AiFillPlusCircle onClick={addTag} />}
				</div>
			</div>
			<div className="buttonGruppe" style={{ textAlign: "center" }}>
				{marked === 0 ? (
					<button onClick={handleMarkieren}>Markieren</button>
				) : marked === 1 ? (
					<button onClick={handleNichtMarkieren}>
						Nicht markieren
					</button>
				) : null}

				<a
					href={exportedData}
					download={`exported_${prompt.Name}.json`}
				>
					<button onClick={handleExport}> Exportieren </button>
				</a>

				{isChangeable === true ? (
					<button onClick={handleLoeschen}>Löschen</button>
				) : (
					""
				)}
				<button onClick={handleZurueck}> Zurück </button>
			</div>
			<datalist id="tags">
				{tags.map((elm) => (
					<option
						value={elm.strDataPromptTag}
						key={`tag_option_${elm.pkDataPromptTagID}`}
					/>
				))}
			</datalist>
		</div>
	);
};

export default PromptItemDetails;
